import React, { useEffect } from 'react';

import { Box, FormControl, FormHelperText, IconButton, InputLabel, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';

import { Field, getIn } from 'formik';
import { FieldAttributes } from 'formik/dist/Field';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import MaybeComponent from '@stewart/common-ui/components/MaybeComponent';
import DropdownIcon from '@stewart/common-ui/icons/DropdownIcon';
import { PermissionStore } from '@stewart/core/redux/reducers/permission.reducer';
import { RootStateType } from '@stewart/core/redux/redux.models';
import { label } from '@stewart/core/services';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '@stewart/theme';

type StewartSelectProps = {
  placeholder?: string;
  name: string;
  options: any[];
  optionsTextField?: string;
  optionsValueField?: string;
  disabled?: boolean;
  onChange?: (e: any, item: any) => void;
  controlStyle?: any;
  readOnly?: boolean;
  isShowSelect?: boolean;
  permissionCode?: string;
  inputClassName?: any;
  isDisableOption?: (option?: any) => boolean;
  caseInsensitiveSelection?: boolean;
  // isTooltipEnabled?: boolean;
  clearInput?: boolean;
};

// Component
const StewartSelect = ({
  placeholder,
  name,
  optionsTextField = 'label',
  optionsValueField = 'value',
  options,
  onChange,
  controlStyle,
  readOnly,
  isShowSelect,
  disabled,
  permissionCode,
  inputClassName,
  isDisableOption,
  caseInsensitiveSelection = false,
  clearInput,
}: // isTooltipEnabled,
StewartSelectProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldAttributes<any>) => {
        const { errors, touched, setFieldValue } = form;
        let { value: fieldValue } = field;
        const isTouched = getIn(touched, name);
        const errorMessage = getIn(errors, name);
        const isInvalid = Boolean(isTouched && errorMessage);
        const { permissionCodes } = useSelector<RootStateType, PermissionStore>(
          (state) => state.permission
        );
        if (caseInsensitiveSelection) {
          const pattern = new RegExp(`^${fieldValue}$`, 'i');
          const selectOption = options.find((item) => pattern.test(item[optionsValueField]));
          fieldValue = selectOption ? selectOption[optionsValueField] : fieldValue;
        }
        const defaultOption = options.length === 1 ? options[0][optionsValueField] : null;

        const handleClear = (value: string) => {
          setFieldValue(name, null);
          onChange?.({ target: { name, value } }, value);
        };

        useEffect(() => {
          if (defaultOption && !fieldValue) {
            setFieldValue(name, defaultOption);
          }
        }, [options]);
        return (
          <FormControl
            variant="outlined"
            error={isInvalid}
            style={{ marginTop: '15px', marginBottom: '30px', ...controlStyle }}
          >
            <InputLabel
              variant="outlined"
              style={{ marginLeft: '-2px', marginTop: '-8px' }}
              htmlFor={name}
            >
              {placeholder}
            </InputLabel>
            <Box position="relative" display="flex" alignItems="center">
              <Select
                {...field}
                value={fieldValue}
                labelId={name}
                id={name}
                label={placeholder}
                onChange={onChange}
                readOnly={readOnly}
                size="small"
                variant="outlined"
                className={inputClassName ? `select ${inputClassName}` : 'select'}
                disabled={(permissionCode && !permissionCodes.includes(permissionCode)) || disabled}
                IconComponent={
                  clearInput && fieldValue
                    ? null
                    : (props) => (
                        <DropdownIcon
                          {...props}
                          htmlColor={
                            isInvalid
                              ? colors.errorColor
                              : disabled
                              ? colors.grey01
                              : colors.dropDownArrowColor
                          }
                        />
                      )
                }
                fullWidth
              >
                {isShowSelect && (
                  <MenuItem key={uuidv4()} value="" className="select-field">
                    {label('lbl_select')}
                  </MenuItem>
                )}
                {options.map((option, ind) => {
                  const optionValue = option[optionsValueField];
                  return (
                    <MenuItem
                      key={uuidv4()}
                      value={optionValue}
                      disabled={isDisableOption?.(option)}
                      sx={{
                        height: ind === 0 && option[optionsTextField] === '' ? '32.57px' : 'unset',
                      }}
                    >
                      {option[optionsTextField]}
                    </MenuItem>
                  );
                })}
              </Select>
              {clearInput && fieldValue && (
                <IconButton
                  aria-label="clear"
                  onClick={() => handleClear('')}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    padding: '0',
                    color: colors.blue14,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
            <MaybeComponent isVisible={isInvalid}>
              <FormHelperText style={{ marginLeft: '12px', position: 'absolute', top: '35px' }}>
                {errorMessage}
              </FormHelperText>
            </MaybeComponent>
          </FormControl>
        );
      }}
    </Field>
  );
};

StewartSelect.defaultProps = {
  optionsTextField: 'label',
  optionsValueField: 'value',
  disabled: false,
  onChange: () => {},
  controlStyle: {},
  readOnly: false,
  placeholder: '',
  isShowSelect: false,
  permissionCode: '',
  inputClassName: '',
  isDisableOption: () => false,
};

export default StewartSelect;
