import React, { useEffect, useRef } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import PermissionUpdatedDialog from '@stewart/common-ui/dialogs/PermissionUpdatedDialog';
import { PermissionUpdatedDialogRefType } from '@stewart/common-ui/dialogs/PermissionUpdatedDialog/models';
import { useAuth } from '@stewart/core/contexts/Auth';
import { getPermissionCodesListAsyncAction } from '@stewart/core/redux/actions/permission';
import { getProfileAsyncAction } from '@stewart/core/redux/actions/profile.action';
import { getTimeZoneListAsyncAction } from '@stewart/core/redux/actions/time-zone.action';
import { PermissionStore } from '@stewart/core/redux/reducers/permission.reducer';
import { RootStateType } from '@stewart/core/redux/redux.models';
import { USER_PERMISSION_CHANGE_LISTENER_API_PATH } from '@stewart/core/rest/api-paths';
import { ProfileDto } from '@stewart/core/rest/models/user.models';
import { message } from '@stewart/core/services';
import { signalRUrl } from '@stewart/core/services/signalRStorage.service';
import { errorHandler, warningHandler } from '@stewart/core/utils';

interface AuthenticatedAppProps {
  children: React.ReactNode;
}

export function AuthenticatedApp({ children }: AuthenticatedAppProps): ReactJSXElement {
  const { requestAccessToken } = useAuth();
  const dispatch = useDispatch();
  const { firstName, userID } = useSelector<RootStateType, ProfileDto>((state) => state.profile);
  const { permissionCodes } = useSelector<RootStateType, PermissionStore>(
    (state) => state.permission
  );

  const stewartPermissionChangeDialog = useRef<PermissionUpdatedDialogRefType>(null);

  useEffect(() => {
    requestAccessToken().then(async (): Promise<void> => {
      await dispatch(getPermissionCodesListAsyncAction());
      await dispatch(getProfileAsyncAction());
      await dispatch(getTimeZoneListAsyncAction());
    });
  }, []);

  function permissionChangedRefresh(): void {
    stewartPermissionChangeDialog?.current?.close();
  }

  // Starts connection to Signal R
  useEffect(() => {
    if (window.location.hostname === 'localhost') return;
    if (userID) {
      const SIGNALR_APP_API_URL = signalRUrl.read();
      const connection = new HubConnectionBuilder()
        .withUrl(`${SIGNALR_APP_API_URL}${USER_PERMISSION_CHANGE_LISTENER_API_PATH}`)
        .withAutomaticReconnect()
        .build();
      connection
        .start()
        .then(() => {
          warningHandler('Connection to Signal R Endpoint completed successfully');
          connection.invoke('AddToGroup', userID).catch(errorHandler);
        })
        .catch(errorHandler);
      connection.on('UserPermissionUpdated', (deletedSets: string[], addedSets: string[]) => {
        if (deletedSets || addedSets) {
          stewartPermissionChangeDialog?.current?.open(deletedSets, addedSets);
        }
      });
    }
  }, [userID]);

  return (
    <>
      <Box>{firstName && permissionCodes && children}</Box>
      <PermissionUpdatedDialog
        ref={stewartPermissionChangeDialog}
        onConfirm={permissionChangedRefresh}
        message={message('msg_permissions_changed')}
        forceConfirmDialog
      />
    </>
  );
}
