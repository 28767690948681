import React, { FC } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Typography } from '@mui/material';
import Chart from 'react-google-charts';

import MaybeComponent from '@stewart/common-ui/components/MaybeComponent';
import {
  ExtendedStewartChartOptionsType,
  StewartChartElementType,
  StewartChartPropsType,
  StewartSelectedRowType,
  StewartSelectionRowType,
} from '@stewart/common-ui/components/StewartCharts/StewartChart/models';
import { Nullable } from '@stewart/core/models';

const DEFAULT_OPTIONS: ExtendedStewartChartOptionsType = {
  pieStartAngle: 0,
  pieSliceText: 'label',
  legend: {
    position: 'none',
  },
  hAxis: {
    logScale: true,
  },
  vAxis: {
    logScale: true,
  },
};

const StewartChart: FC<StewartChartPropsType> = ({
  className,
  width,
  height,
  data,
  dataColumn,
  onSelect,
  options,
  title,
  innerComponent,
  chartType,
  style,
}: StewartChartPropsType): ReactJSXElement => {
  let previousSelectionRow: StewartSelectionRowType;
  const chartData = [
    dataColumn,
    ...data.map(({ label, value }: StewartChartElementType) => {
      let fullValue: Nullable<number>[];
      if (Array.isArray(value)) {
        fullValue = [...value];
      } else {
        fullValue = [value];
      }
      return [label, ...fullValue];
    }),
  ];
  return (
    <Box className={className}>
      <MaybeComponent isVisible={!!title}>
        <Typography
          variant="h6"
          style={{ padding: '0 24px', textAlign: 'center', position: 'relative', zIndex: 2 }}
        >
          {title}
        </Typography>
      </MaybeComponent>
      {innerComponent}
      <Chart
        width={width}
        height={height}
        style={style}
        chartType={chartType || 'PieChart'}
        data={chartData}
        options={{
          ...DEFAULT_OPTIONS,
          ...options,
        }}
        chartEvents={[
          {
            eventName: 'select', // TODO Need to improve this code, because we use 'select' instead of 'click' (Google doesn't provide type for this moment)
            callback: ({ chartWrapper }) => {
              const selection: StewartSelectionRowType = chartWrapper.getChart().getSelection()[0];
              if (selection) {
                previousSelectionRow = { ...selection };
              }
              const index = previousSelectionRow!.row;
              const { label } = data[index];
              const selectedRow: StewartSelectedRowType = {
                selectedLabel: label,
                selectedIndex: index,
              };
              onSelect?.(selectedRow);
            },
          },
        ]}
      />
    </Box>
  );
};

StewartChart.defaultProps = {
  className: '',
  dataColumn: ['', ''],
  options: DEFAULT_OPTIONS,
  title: '',
};

export default StewartChart;
