import React from 'react';
import { colors } from '..';

const FilePlusMinusIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="gradientFill" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: colors.lightMaroon, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: colors.maroon03, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path
        d="M7 2C5.62109 2 4.5 3.12109 4.5 4.5V19.5C4.5 20.8789 5.62109 22 7 22H17C18.3789 22 19.5 20.8789 19.5 19.5V8.25H14.5C13.8086 8.25 13.25 7.69141 13.25 7V2H7ZM14.5 2V7H19.5L14.5 2ZM12.625 10.125V12H14.5C14.8438 12 15.125 12.2812 15.125 12.625C15.125 12.9688 14.8438 13.25 14.5 13.25H12.625V15.125C12.625 15.4688 12.3438 15.75 12 15.75C11.6562 15.75 11.375 15.4688 11.375 15.125V13.25H9.5C9.15625 13.25 8.875 12.9688 8.875 12.625C8.875 12.2812 9.15625 12 9.5 12H11.375V10.125C11.375 9.78125 11.6562 9.5 12 9.5C12.3438 9.5 12.625 9.78125 12.625 10.125ZM9.5 17H14.5C14.8438 17 15.125 17.2812 15.125 17.625C15.125 17.9688 14.8438 18.25 14.5 18.25H9.5C9.15625 18.25 8.875 17.9688 8.875 17.625C8.875 17.2812 9.15625 17 9.5 17Z"
        fill="white"
      />
    </svg>
  );
};

export default FilePlusMinusIcon;
