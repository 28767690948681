import React, { forwardRef, ReactNode, Ref, useImperativeHandle, useRef, useState } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Button } from '@mui/material';

import StewartDialog from '@stewart/common-ui/dialogs/StewartDialog';
import { StewartDialogRefType } from '@stewart/common-ui/dialogs/StewartDialog/models';
import {
  AllowedStewartNotifyDialogTypes,
  StewartNotifyDialogPropsType,
  StewartNotifyDialogRefType,
} from '@stewart/common-ui/dialogs/StewartNotifyDialog/models';
import TickIcon from '@stewart/common-ui/icons/TickIcon';
import { label } from '@stewart/core/services';

// Component
const StewartNotifyDialog = (
  { type, onCancel, message, title, dialogStyle = {} }: StewartNotifyDialogPropsType,
  ref: Ref<StewartNotifyDialogRefType>
) => {
  const stewartDialogRef = useRef<StewartDialogRefType>(null);
  const [typeState, setTypeState] = useState<AllowedStewartNotifyDialogTypes>('success');
  const [messageState, setMessageState] = useState<string | ReactNode>('');

  useImperativeHandle(ref, () => ({
    open: (
      internalType?: AllowedStewartNotifyDialogTypes,
      internalMessage?: string | ReactNode
    ) => {
      if (internalType) {
        setTypeState(internalType);
      }
      if (internalMessage) {
        setMessageState(internalMessage);
      }
      stewartDialogRef?.current?.open();
    },
    close: () => {
      stewartDialogRef?.current?.close();
    },
  }));

  function contentBlock(): ReactJSXElement {
    return (
      <Box
        style={{
          maxWidth: dialogStyle.maxWidth ? dialogStyle.maxWidth : '28vw',
          overflowWrap: 'break-word',
        }}
      >
        {message || messageState}
      </Box>
    );
  }

  function actionsBlock(): ReactJSXElement {
    return (
      <Box>
        <Button
          style={{ minWidth: dialogStyle.width ? dialogStyle.width : '120px' }}
          variant="contained"
          onClick={() => onCancel?.()}
          startIcon={<TickIcon />}
        >
          {label('lbl_ok')}
        </Button>
      </Box>
    );
  }

  return (
    <>
      <StewartDialog
        ref={stewartDialogRef}
        title={title}
        contentBlock={contentBlock}
        actionsBlock={actionsBlock}
        type={type || typeState}
      />
    </>
  );
};

export default forwardRef<StewartNotifyDialogRefType, StewartNotifyDialogPropsType>(
  StewartNotifyDialog
);
