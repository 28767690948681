import React from 'react';

const ProfileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path
        d="M4.09844 15.975C5.14844 15.3083 6.18177 14.8125 7.19844 14.4875C8.2151 14.1625 9.3151 14 10.4984 14C11.6818 14 12.7901 14.1667 13.8234 14.5C14.8568 14.8333 15.8901 15.325 16.9234 15.975C17.6401 15.0583 18.1443 14.1625 18.4359 13.2875C18.7276 12.4125 18.8734 11.4833 18.8734 10.5C18.8734 8.13333 18.0693 6.14583 16.4609 4.5375C14.8526 2.92917 12.8651 2.125 10.4984 2.125C8.13177 2.125 6.14427 2.92917 4.53594 4.5375C2.9276 6.14583 2.12344 8.13333 2.12344 10.5C2.12344 11.4833 2.27344 12.4125 2.57344 13.2875C2.87344 14.1625 3.38177 15.0583 4.09844 15.975ZM10.4984 11.35C9.49844 11.35 8.6651 11.0125 7.99844 10.3375C7.33177 9.6625 6.99844 8.83333 6.99844 7.85C6.99844 6.85 7.33594 6.0125 8.01094 5.3375C8.68594 4.6625 9.5151 4.325 10.4984 4.325C11.4984 4.325 12.3318 4.66667 12.9984 5.35C13.6651 6.03333 13.9984 6.86667 13.9984 7.85C13.9984 8.83333 13.6609 9.6625 12.9859 10.3375C12.3109 11.0125 11.4818 11.35 10.4984 11.35ZM10.4984 20.85C9.0651 20.85 7.71927 20.5792 6.46094 20.0375C5.2026 19.4958 4.1026 18.7542 3.16094 17.8125C2.21927 16.8708 1.48177 15.7708 0.948438 14.5125C0.415104 13.2542 0.148438 11.9167 0.148438 10.5C0.148438 9.06667 0.419271 7.72083 0.960938 6.4625C1.5026 5.20417 2.24427 4.10833 3.18594 3.175C4.1276 2.24167 5.2276 1.5 6.48594 0.95C7.74427 0.4 9.0901 0.125 10.5234 0.125C11.9401 0.125 13.2776 0.4 14.5359 0.95C15.7943 1.5 16.8901 2.24167 17.8234 3.175C18.7568 4.10833 19.4984 5.20417 20.0484 6.4625C20.5984 7.72083 20.8734 9.06667 20.8734 10.5C20.8734 11.9167 20.5984 13.2542 20.0484 14.5125C19.4984 15.7708 18.7568 16.8708 17.8234 17.8125C16.8901 18.7542 15.7943 19.4958 14.5359 20.0375C13.2776 20.5792 11.9318 20.85 10.4984 20.85Z"
        fill="white"
      />
    </svg>
  );
};

export default ProfileIcon;
