import React from 'react';

const ErrorIcon = () => {
  return (
    <>
      <svg
        width="44"
        height="38"
        viewBox="0 0 44 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 38H44L22 0L0 38ZM24 32H20V28H24V32ZM24 24H20V16H24V24Z" fill="white" />
      </svg>
    </>
  );
};

export default ErrorIcon;
