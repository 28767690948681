import React from 'react';

const UploadFileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M19.41 7.91L14.58 3.08C14.21 2.71 13.7 2.5 13.17 2.5H6C4.9 2.5 4.01 3.4 4.01 4.5L4 20.5C4 21.6 4.89 22.5 5.99 22.5H18C19.1 22.5 20 21.6 20 20.5V9.33C20 8.8 19.79 8.29 19.41 7.91ZM14.8 15.5H13V18.5C13 19.05 12.55 19.5 12 19.5C11.45 19.5 11 19.05 11 18.5V15.5H9.21C8.76 15.5 8.54 14.96 8.86 14.65L11.66 11.86C11.86 11.67 12.17 11.67 12.37 11.86L15.16 14.65C15.46 14.96 15.24 15.5 14.8 15.5ZM14 9.5C13.45 9.5 13 9.05 13 8.5V4L18.5 9.5H14Z"
        fill="white"
      />
    </svg>
  );
};

export default UploadFileIcon;
