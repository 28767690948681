import { createStyles, makeStyles } from '@mui/styles';
import { colors, fontFamily, fontSize } from '@stewart/theme/index';

export const useFooterStyles = makeStyles(() =>
  createStyles({
    footer: {
      position: 'absolute',
      textAlign: 'left',
      paddingLeft: '15px',
      paddingTop: '25px',
      fontFamily: fontFamily.primary,
      width: '98vw',
      backgroundColor: 'rgb(229, 229, 229)',
    },
    links: {
      marginTop: '20px',
      paddingBottom: '5px',
    },
    copyright: {
      fontSize: fontSize.medium,
      lineHeight: '21px',
      marginTop: '20px',
    },
    link: {
      color: colors.blue14,
      fontSize: '12px',
      fontWeight: '700',
      paddingRight: '20px',
      textDecoration: 'none',
    },
  })
);
