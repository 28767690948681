/**
 * Handler Method Of Error
 * @param error
 */
import { Search } from 'history';

import { InitialValue } from '@stewart/common-ui/dialogs/EditCompanySignatureDialog/models';
import { ErrorType, Nullable, ObjectType, ValidationErrorType } from '@stewart/core/models';

export function errorHandler(error: any): void {
  // eslint-disable-next-line no-console
  console.error(error);
}

export function warningHandler(message: string): void {
  // eslint-disable-next-line no-console
  console.warn(message);
}

export function logHandler(message: any): void {
  // eslint-disable-next-line no-console
  console.log(message);
}

/**
 * Return Error Message
 * @param {ErrorType | any} error
 * @return {string}
 */
export function getErrorMessage(error: ErrorType | any): string {
  return error.response?.data?.messages ?? error.message;
}

/**
 * Set Errors To Form
 * @param {Function} setErrors
 * @param {ErrorType} error
 */
export function setFormErrors(setErrors: Function, error: ErrorType): void {
  if (error.response?.data?.validationErrors?.length) {
    setErrors(
      error.response.data.validationErrors.reduce(
        (errors: ObjectType, { field, message }: ValidationErrorType): ObjectType => {
          return { ...errors, [field]: message };
        },
        {}
      )
    );
  }
}

/**
 * Generate Csv File
 * @param {string[][]} dataList
 * @return {string}
 */
export function generateCsvFile(dataList: string[][]): File {
  const fileData: string = dataList.map((row: string[]) => row.join(',')).join('\n');
  const blob: Blob = new Blob([fileData], { type: 'text/csv;charset=utf-8;' });

  return new File([blob], 'users.csv');
}

/**
 * Return File Data
 * @param {File} file
 * @return {Promise<any>}
 */
export function getFileData<T = any>(file: File): Promise<T> {
  return new Promise((onSuccess) => {
    const reader: FileReader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const data: any = event?.target?.result;

      if (data) {
        onSuccess(data);
      }
    };
    reader.readAsText(file);
  });
}

/**
 * Return Base64 Data
 * @param {File} file
 * @return {Promise<any>}
 */
export function getBase64Data<T = any>(file: File): Promise<T> {
  return new Promise((onSuccess) => {
    const reader: FileReader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const data: any = event?.target?.result;

      if (data) {
        onSuccess(data);
      }
    };
    reader.readAsDataURL(file);
  });
}

/**
 * Return Route Query Param
 * @param {Search} search
 * @param {string} paramName
 * @return {string | null}
 */
export function getQueryParam(search: Search, paramName: string): Nullable<string> {
  return new URLSearchParams(search).get(paramName);
}

/**
 * Return Boolean Value
 * @return {boolean}
 * @param arr1
 * @param arr2
 */
export function diff(arr1: Array<any>, arr2: Array<any>): boolean {
  return arr1.every((v, i) => arr2[i] === v);
}

/**
 * Return Base64 data with Meta info
 * @param {string} imageData
 * @return {string}
 */
export function restoreMetaForBase64(imageData: string): string {
  const isCorrectImageData = imageData.includes('base');
  if (!isCorrectImageData) {
    return InitialValue.BASE_64_META + imageData;
  }
  return imageData;
}

export async function getBlobUrlBySrc(src: string): Promise<string> {
  const result = await fetch(src);
  const blob = await result.blob();
  return URL.createObjectURL(blob);
}

export function getBlobUrlByResponseData(data: any) {
  const blob = new Blob([data], { type: 'application/zip' });
  return URL.createObjectURL(blob);
}

export const downloadZipFile = async (urlBlob: string, fileName?: string): Promise<void> => {
  const anchor = document.createElement('a');
  anchor.href = urlBlob;
  if (fileName) {
    anchor.download = fileName;
  }

  document.body.appendChild(anchor);
  anchor.click();
};

export const redirectToNewPage = (url: string) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.target = '_blank';

  document.body.appendChild(anchor);
  anchor.click();
};

export const getEmptyLoadForGridApi = <T>(field: keyof T | string = 'id') => {
  return {
    currentPage: 0,
    rowsPerPage: 0,
    sort: { column: field, direction: 'asc' },
  };
};

export function getHTMLfileLinkFromBase64(base64: string): string {
  const decodedContent = new TextDecoder('utf-8').decode(
    new Uint8Array(
      atob(base64)
        .split('')
        .map((char) => char.charCodeAt(0))
    )
  );
  const blob = new Blob([decodedContent], { type: 'text/html; charset=utf-8' });
  return URL.createObjectURL(blob);
}

export function hasValue(value: any) {
  let result = false;
  if (typeof value !== 'undefined') {
    if (typeof value === 'number') {
      result = true;
    } else if (typeof value === 'boolean') {
      result = true;
    } else if (value) {
      result = true;
    }
  }
  return result;
}

export function getCorrelationId(httpResponseHeaders: any): string {
  if (httpResponseHeaders && httpResponseHeaders['x-correlation-id']) {
    return httpResponseHeaders['x-correlation-id'];
  }
  return '';
}
