import React, { Component, ErrorInfo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor() {
    // @ts-ignore
    super();

    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, error, errorInfo });
  }

  public render() {
    const { error, hasError, errorInfo } = this.state;
    const { children } = this.props;

    return hasError ? (
      <>
        <span>Something went wrong</span>
        <div>{error}</div>
        <div>{errorInfo}</div>
      </>
    ) : (
      children
    );
  }
}
