import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { StewartTextStylePropsType } from '@stewart/common-ui/components/StewartText/models';

export const useStewartTextStyles = makeStyles<Theme, StewartTextStylePropsType>(() => ({
  textContainer: {
    maxHeight: ({ maxRows, lineHeight }: StewartTextStylePropsType): string =>
      `${maxRows! * lineHeight!}px`,
    lineHeight: ({ lineHeight }: StewartTextStylePropsType): string => `${lineHeight!}`,
  },
}));
