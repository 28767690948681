import React from 'react';

const TableIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 12V8.14286H10.75V12H4.5ZM4.5 14.5714H10.75V18.4286H4.5V14.5714ZM13.25 18.4286V14.5714H19.5V18.4286H13.25ZM19.5 12H13.25V8.14286H19.5V12ZM4.5 3C3.12109 3 2 4.15313 2 5.57143V18.4286C2 19.8469 3.12109 21 4.5 21H19.5C20.8789 21 22 19.8469 22 18.4286V5.57143C22 4.15313 20.8789 3 19.5 3H4.5Z"
        fill="white"
      />
    </svg>
  );
};

export default TableIcon;
