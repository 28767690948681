import React from 'react';

const NineDotsIcon = () => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 1.16071C0 0.51942 0.559375 0 1.25 0H2.75C3.44062 0 4 0.51942 4 1.16071V2.55357C4 3.19487 3.44062 3.71429 2.75 3.71429H1.25C0.559375 3.71429 0 3.19487 0 2.55357V1.16071ZM0 5.80357C0 5.16228 0.559375 4.64286 1.25 4.64286H2.75C3.44062 4.64286 4 5.16228 4 5.80357V7.19643C4 7.83772 3.44062 8.35714 2.75 8.35714H1.25C0.559375 8.35714 0 7.83772 0 7.19643V5.80357ZM4 10.4464V11.8393C4 12.4806 3.44062 13 2.75 13H1.25C0.559375 13 0 12.4806 0 11.8393V10.4464C0 9.80513 0.559375 9.28572 1.25 9.28572H2.75C3.44062 9.28572 4 9.80513 4 10.4464ZM5 1.16071C5 0.51942 5.55937 0 6.25 0H7.75C8.44062 0 9 0.51942 9 1.16071V2.55357C9 3.19487 8.44062 3.71429 7.75 3.71429H6.25C5.55937 3.71429 5 3.19487 5 2.55357V1.16071ZM9 5.80357V7.19643C9 7.83772 8.44062 8.35714 7.75 8.35714H6.25C5.55937 8.35714 5 7.83772 5 7.19643V5.80357C5 5.16228 5.55937 4.64286 6.25 4.64286H7.75C8.44062 4.64286 9 5.16228 9 5.80357ZM5 10.4464C5 9.80513 5.55937 9.28572 6.25 9.28572H7.75C8.44062 9.28572 9 9.80513 9 10.4464V11.8393C9 12.4806 8.44062 13 7.75 13H6.25C5.55937 13 5 12.4806 5 11.8393V10.4464ZM14 1.16071V2.55357C14 3.19487 13.4406 3.71429 12.75 3.71429H11.25C10.5594 3.71429 10 3.19487 10 2.55357V1.16071C10 0.51942 10.5594 0 11.25 0H12.75C13.4406 0 14 0.51942 14 1.16071ZM10 5.80357C10 5.16228 10.5594 4.64286 11.25 4.64286H12.75C13.4406 4.64286 14 5.16228 14 5.80357V7.19643C14 7.83772 13.4406 8.35714 12.75 8.35714H11.25C10.5594 8.35714 10 7.83772 10 7.19643V5.80357ZM14 10.4464V11.8393C14 12.4806 13.4406 13 12.75 13H11.25C10.5594 13 10 12.4806 10 11.8393V10.4464C10 9.80513 10.5594 9.28572 11.25 9.28572H12.75C13.4406 9.28572 14 9.80513 14 10.4464Z"
        fill="white"
      />
    </svg>
  );
};

export default NineDotsIcon;
