import React from 'react';

import { Box, CircularProgress } from '@mui/material';

import { usePreloader } from '@stewart/core/contexts/Preloader';

function StewartPreloader() {
  const { isOnPreloader } = usePreloader();

  return isOnPreloader ? (
    <Box
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      }}
    >
      <CircularProgress size="70px" />
    </Box>
  ) : null;
}

export default StewartPreloader;
