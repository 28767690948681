import { ActionType } from '@stewart/core/redux/redux.models';
import { GET_TIME_ZONE_LIST_ACTION } from '@stewart/core/redux/types/time-zone.type';
import { TimeZoneDto } from '@stewart/core/rest/models/lookup.models';

export function timeZoneReducer(state: TimeZoneDto[] = [], action: ActionType): TimeZoneDto[] {
  switch (action.type) {
    case GET_TIME_ZONE_LIST_ACTION:
      return action.payload;
    default:
      return state;
  }
}
