export type RevisionPropsType = {
  revisionInitialValues: ReviseRequestDto;
  callbackForField: (partialData: Partial<RevisionFieldsType>) => void;
  refresh: () => Promise<void>;
  isReadOnly: boolean;
  userType: string;
};

export type RevisionFieldsType = {
  revisionDate: string;
  revisedByName: string;
  originalApprovedAmount?: string;
  originalApprovedDate?: string;
  revisionInfo?: string;
};

export type ReviseRequestDto = {
  revisedByID: string;
  revisedByName: string;
  revisionDate: string;
  revisionInfo?: string;
  originalApprovedAmount?: string;
  originalApprovedDate?: string;
};

export const REVISION_INITIAL_VALUES = {
  revisedByID: '',
  revisedByName: '',
  revisionDate: '',
  revisionInfo: '',
  originalApprovedAmount: '',
  originalApprovedDate: '',
};
