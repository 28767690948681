import { httpRequests } from '@stewart/core/http';
import {
  DASHBOARD_BASIC_COUNTS_API_PATH,
  DASHBOARD_FILES_BY_STATE_API_PATH,
  DASHBOARD_PRODUCT_SUMMARY_API_PATH,
} from '@stewart/core/rest/api-paths';
import {
  DashboardBasicCounts,
  FilesByState,
  ProductSummary,
  ProductSummaryByState,
} from '@stewart/core/rest/models/dashboard';
import { GridResponseType } from '@stewart/core/rest/models/grid.models';

/**
 * @returns {Promise<DashboardBasicCounts>}
 */

export function getBasicCounts(daysBack: number): Promise<DashboardBasicCounts> {
  return httpRequests.get(`${DASHBOARD_BASIC_COUNTS_API_PATH}?daysBack=${daysBack}`);
}

/**
 * @returns {Promise<GridResponseType<FilesByState>>}
 */

export function getFileSummaryByState(daysBack: number): Promise<GridResponseType<FilesByState>> {
  return httpRequests.post(DASHBOARD_FILES_BY_STATE_API_PATH, { daysBack });
}

/**
 * @returns {Promise<GridResponseType<ProductSummary>>}
 */

export function getProductSummary(daysBack: number): Promise<GridResponseType<ProductSummary>> {
  return httpRequests.post(DASHBOARD_PRODUCT_SUMMARY_API_PATH, { daysBack });
}

/**
 * @returns {Promise<GridResponseType<ProductSummaryByState>>}
 */

export function getProductSummaryByState(
  daysBack: number
): Promise<GridResponseType<ProductSummaryByState>> {
  return httpRequests.post(`${DASHBOARD_PRODUCT_SUMMARY_API_PATH}?ByParam=ByState`, { daysBack });
}
