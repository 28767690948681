import React, { Context, createContext, useContext } from 'react';

import { AuthenticationResult } from '@azure/msal-common';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { AuthContextType } from '@stewart/core/contexts/Auth/models';
import { validateLogin } from '@stewart/core/rest/requests/validateLogin.rest';
import { tokenStorage, searchUserStorage } from '@stewart/core/services';
import { errorHandler } from '@stewart/core/utils/helper.utils';

const REFRESH_TOKEN_TIMEOUT: number = 1000 * 60 * 30;

const AuthContext: Context<AuthContextType> = createContext<AuthContextType>({} as AuthContextType);

// Provider
export function AuthProvider({ children, loginRequest, passwordResetRequest }: any) {
  const { instance, accounts } = useMsal();

  /**
   * Sign Out Method
   */
  async function signOut(): Promise<void> {
    tokenStorage.remove();
    searchUserStorage.remove();
    await instance
      .logoutRedirect({ postLogoutRedirectUri: window.location.origin })
      .catch(errorHandler);
  }

  /**
   * Update Token Info Method
   * @param accessToken
   */
  function updateTokenInfo(accessToken: string): void {
    if (accessToken) {
      tokenStorage.write(accessToken);
      const navigation: any = performance.getEntriesByType('navigation')[0];
      if (navigation.type !== 'reload') {
        validateLogin();
      }
    }
  }

  function invokeAcquireToken(): Promise<void | AuthenticationResult> {
    const request = {
      ...loginRequest,
      account: accounts[0],
      forceRefresh: true,
      redirectUri: `${window.location.origin}/`,
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    return instance
      .acquireTokenSilent(request)
      .then(({ accessToken }: AuthenticationResult) => {
        updateTokenInfo(accessToken);
      })

      .catch(() => {
        return instance.acquireTokenPopup(request).then(({ accessToken }: AuthenticationResult) => {
          updateTokenInfo(accessToken);
        });
      });
  }

  function requestAccessToken(): Promise<void | AuthenticationResult> {
    return invokeAcquireToken().then(() => {
      setTimeout(async () => {
        await requestAccessToken();
      }, REFRESH_TOKEN_TIMEOUT);
    });
  }

  return (
    <AuthContext.Provider
      value={{
        signOut,
        requestAccessToken,
        passwordResetRequest,
        useIsAuthenticated,
        loginRequest,
        instance,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
