import React from 'react';

const KeyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15.125 15.75C18.9219 15.75 22 12.6719 22 8.875C22 5.07812 18.9219 2 15.125 2C11.3281 2 8.25 5.07812 8.25 8.875C8.25 9.60547 8.36328 10.3125 8.57422 10.9727L2.27344 17.2734C2.09766 17.4492 2 17.6875 2 17.9375V21.0625C2 21.582 2.41797 22 2.9375 22H6.0625C6.58203 22 7 21.582 7 21.0625V19.5H8.5625C9.08203 19.5 9.5 19.082 9.5 18.5625V17H11.0625C11.3125 17 11.5508 16.9023 11.7266 16.7266L13.0273 15.4258C13.6875 15.6367 14.3945 15.75 15.125 15.75ZM16.6875 5.75C17.1019 5.75 17.4993 5.91462 17.7924 6.20765C18.0854 6.50067 18.25 6.8981 18.25 7.3125C18.25 7.7269 18.0854 8.12433 17.7924 8.41735C17.4993 8.71038 17.1019 8.875 16.6875 8.875C16.2731 8.875 15.8757 8.71038 15.5826 8.41735C15.2896 8.12433 15.125 7.7269 15.125 7.3125C15.125 6.8981 15.2896 6.50067 15.5826 6.20765C15.8757 5.91462 16.2731 5.75 16.6875 5.75Z"
        fill="white"
      />
    </svg>
  );
};

export default KeyIcon;
