import { ActionType } from '@stewart/core/redux/redux.models';
import {
  SET_CALCULATION_DATA_ACTION,
  SET_REQUIRED_FIELDS,
} from '@stewart/core/redux/types/uw.type';
import {
  APPROVAL_LEVEL_CALCULATION_DEFAULT_VALUES,
  REQUIRED_FIELDS_INITIAL_VALUES,
  UWStore,
} from '@stewart/pages/UWPage/models';

const INITIAL_STATE: UWStore = {
  levelCalculationData: APPROVAL_LEVEL_CALCULATION_DEFAULT_VALUES,
  requiredFields: REQUIRED_FIELDS_INITIAL_VALUES,
};

export function uwReducer(state: UWStore = INITIAL_STATE, { type, payload }: ActionType): UWStore {
  switch (type) {
    case SET_CALCULATION_DATA_ACTION:
      return {
        ...state,
        levelCalculationData: { ...state.levelCalculationData, ...payload },
      };
    case SET_REQUIRED_FIELDS:
      return { ...state, requiredFields: { ...state.requiredFields, ...payload } };
    default:
      return state;
  }
}
