import React from 'react';

const WarningIcon = () => {
  return (
    <>
      <svg
        style={{ display: 'flex' }}
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM22 30H18V26H22V30ZM22 22H18V10H22V22Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default WarningIcon;
