import loadable from '@loadable/component';

import { PERMISSIONS } from '@stewart/common-ui/components/AuthenticatedApp/utils';
import { RouteType } from '@stewart/core/routing/models';
import HomePage from '@stewart/pages/HomePage';

// Lazy loaded components
const UsersPage = loadable<any>(() => import('@stewart/pages/UsersPage'));
const PermissionsPage = loadable<any>(() => import('@stewart/pages/PermissionsPage'));
const CachePage = loadable<any>(() => import('@stewart/pages/CachePage'));
const LogsPage = loadable<any>(() => import('@stewart/pages/LogsPage'));
const TypeTablesPage = loadable<any>(() => import('@stewart/pages/TypeTablesPage'));
const CompaniesPage = loadable<any>(() => import('@stewart/pages/CompaniesPage'));
const ConfigurationPage = loadable<any>(() => import('@stewart/pages/ConfigurationPage'));
const FileManagementPage = loadable<any>(() => import('@stewart/pages/FileManagementPage'));
const UWRequestPage = loadable<any>(() => import('@stewart/pages/UWRequestPage'));
const HelpCenterPage = loadable<any>(() => import('@stewart/pages/HelpCenterPage'));
const UWViewPage = loadable<any>(() => import('@stewart/pages/UWPage'));
const CompaniesContractPage = loadable<any>(() => import('@stewart/pages/CompaniesContractPage'));
const PolicyUploadPage = loadable<any>(() => import('@stewart/pages/Policyupload'));

// Routes' paths constants
export const ROOT_PATH: string = `/home`;
export const USERS_PATH: string = `/users`;
export const PERMISSIONS_PATH: string = `/permissions`;
export const CACHE_PATH: string = `/cache`;
export const LOGS_PATH: string = `/logs`;
export const TYPE_TABLES_PATH: string = `/type-tables`;
export const COMPANIES_PATH: string = `/companies`;
export const CONFIGURATION_PATH: string = `/configuration`;
export const FILE_MANAGEMENT_PATH: string = `/filemanagement`;
export const UWREQUEST_PATH: string = `/uw-request`;
export const HELP_CENTER_PATH: string = `/help-center`;
export const POLICY_UPLOAD_PATH: string = `/policyupload`;
export const TEST_MAIN_GRID_PATH: string = '/testmaingrid';

// Routes config
export const ROOT_ROUTES: RouteType[] = [
  {
    path: ROOT_PATH,
    exact: true,
    component: HomePage,
  },
  {
    path: USERS_PATH,
    exact: true,
    component: UsersPage,
    permissionCode: PERMISSIONS.USERS_ACCESS,
  },
  {
    path: PERMISSIONS_PATH,
    exact: true,
    component: PermissionsPage,
    permissionCode: PERMISSIONS.PERMISSIONS_ACCESS,
  },
  {
    path: CACHE_PATH,
    exact: true,
    component: CachePage,
    permissionCode: PERMISSIONS.CACHE_ACCESS,
  },
  {
    path: LOGS_PATH,
    exact: true,
    component: LogsPage,
    permissionCode: PERMISSIONS.LOGS_ACCESS,
  },
  {
    path: TYPE_TABLES_PATH,
    exact: true,
    component: TypeTablesPage,
    permissionCode: PERMISSIONS.TYPE_TABLE_ACCESS,
  },
  {
    path: COMPANIES_PATH,
    exact: true,
    component: CompaniesPage,
    permissionCode: PERMISSIONS.COMPANY_ACCESS,
  },
  {
    path: `${COMPANIES_PATH}/:companyID`,
    exact: true,
    component: CompaniesContractPage,
    permissionCode: PERMISSIONS.COMPANY_ACCESS,
  },
  {
    path: CONFIGURATION_PATH,
    exact: true,
    component: ConfigurationPage,
    permissionCode: PERMISSIONS.CONFIG_ADMIN_ACCESS,
  },
  {
    path: FILE_MANAGEMENT_PATH,
    exact: true,
    component: FileManagementPage,
    permissionCode: PERMISSIONS.FILE_MANAGEMENT_ACCESS,
  },
  {
    path: `${UWREQUEST_PATH}/:uwaRequestNumber`,
    exact: true,
    component: UWViewPage,
    permissionCode: PERMISSIONS.UWA_ACCESS,
  },
  {
    path: UWREQUEST_PATH,
    exact: false,
    component: UWRequestPage,
    permissionCode: PERMISSIONS.UWA_ACCESS,
  },
  {
    path: HELP_CENTER_PATH,
    exact: false,
    component: HelpCenterPage,
    permissionCode: PERMISSIONS.HELP_CENTER_ACCESS,
  },
  {
    path: HELP_CENTER_PATH,
    exact: false,
    component: HelpCenterPage,
    permissionCode: PERMISSIONS.HELP_CENTER_ACCESS,
  },
  {
    path: POLICY_UPLOAD_PATH,
    exact: false,
    component: PolicyUploadPage,
    permissionCode: PERMISSIONS.UPLOADS_ADMIN_ACCESS,
  },
  {
    path: '*',
    redirect: ROOT_PATH,
  },
];
