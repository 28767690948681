import React from 'react';

import { createBrowserHistory } from 'history';
// @ts-ignore
import { passiveSupport } from 'passive-events-support/src/utils';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ConfigurationApp } from '@stewart/common-ui/components/AuthenticatedApp/components/ConfiguratedApp';
import { PreloaderProvider } from '@stewart/core/contexts/Preloader';
import ErrorBoundary from '@stewart/core/ErrorBoundary';
import initStore from '@stewart/core/redux/store';

import reportWebVitals from './reportWebVitals';

import './index.css';

passiveSupport({
  debug: false,
});

const history = createBrowserHistory();
const store = initStore(history);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PreloaderProvider>
        <ConfigurationApp history={history} />
      </PreloaderProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
