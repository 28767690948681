import React, { createContext, ReactElement, RefObject, useContext, useRef } from 'react';

import { StewartGridRefType } from '@stewart/common-ui/components/StewartGrid/models';

export type ComponentRefsContextType = {
  stewartUserGridRef: RefObject<StewartGridRefType>;
};

// Context
const ComponentRefsContext = createContext<ComponentRefsContextType>(
  {} as ComponentRefsContextType
);

// Provider
export function ComponentRefsProvider({ children }: { children: ReactElement }) {
  const stewartUserGridRef: RefObject<StewartGridRefType> = useRef<StewartGridRefType>(null);

  return (
    <ComponentRefsContext.Provider value={{ stewartUserGridRef }}>
      {children}
    </ComponentRefsContext.Provider>
  );
}

export const useComponentRefs = (): ComponentRefsContextType =>
  useContext<ComponentRefsContextType>(ComponentRefsContext);
