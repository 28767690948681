import { makeStyles } from '@mui/styles';

export const useHomePageStyles = makeStyles(() => ({
  dashboardContainer: {
    margin: '24px 33px',
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '0px 2px 6px rgb(0 0 0 / 25%)',
  },
  chartContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'center',
  },
  chartItem: {},
  chartItemFiles: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    paddingBottom: '20px',
  },
}));
