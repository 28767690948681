import React, { useState, useRef } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CachedIcon from '@stewart/theme/icons/CacheIcon';
import PeopleIcon from '@stewart/theme/icons/PeopleIcon';
import SettingsIcon from '@stewart/theme/icons/SettingsIcon';
import BuildingIcon from '@stewart/theme/icons/BuildingIcon';
import FilePenIcon from '@stewart/theme/icons/FilePenIcon';
import FileDescriptionIcon from '@stewart/theme/icons/FileDescriptionIcon';
import NineDotsIcon from '@stewart/theme/icons/NineDotsIcon';
import UploadFileIcon from '@stewart/theme/icons/UploadFileIcon';
import TableIcon from '@stewart/theme/icons/TableIcon';
import FilePlusMinusIcon from '@stewart/theme/icons/FilePlusMinusIcon';
import KeyIcon from '@stewart/theme/icons/KeyIcon';
import ProfileIcon from '@stewart/theme/icons/ProfileIcon';
import { mediaSize } from '@stewart/theme/index';
import {
  AppBar,
  MenuItem,
  Toolbar,
  Box,
  Button,
  ClickAwayListener,
  Fade,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { PERMISSIONS } from '@stewart/common-ui/components/AuthenticatedApp/utils';
import MaybeComponent from '@stewart/common-ui/components/MaybeComponent';
import MobileNavbar from '@stewart/common-ui/components/StewartHeader/components/MobileNavbar';
import NavigationItem from '@stewart/common-ui/components/StewartHeader/components/NavigationItem';
import { NavigationItemPropsType } from '@stewart/common-ui/components/StewartHeader/components/NavigationItem/models';
import { useHeaderStyles } from '@stewart/common-ui/components/StewartHeader/style';
import { StewartText } from '@stewart/common-ui/components/StewartText';
import EditProfileDialog from '@stewart/common-ui/dialogs/EditProfileDialog';
import { EditProfileDialogRefType } from '@stewart/common-ui/dialogs/EditProfileDialog/models';
import LogoIcon from '@stewart/common-ui/icons/LogoIcon';
import { useAuth } from '@stewart/core/contexts/Auth';
import { PermissionStore } from '@stewart/core/redux/reducers/permission.reducer';
import { RootStateType } from '@stewart/core/redux/redux.models';
import { ProfileDto } from '@stewart/core/rest/models/user.models';
import {
  CACHE_PATH,
  COMPANIES_PATH,
  CONFIGURATION_PATH,
  FILE_MANAGEMENT_PATH,
  HELP_CENTER_PATH,
  LOGS_PATH,
  PERMISSIONS_PATH,
  POLICY_UPLOAD_PATH,
  ROOT_PATH,
  TYPE_TABLES_PATH,
  USERS_PATH,
  UWREQUEST_PATH,
} from '@stewart/core/routing/routes/root.routes';
import { label } from '@stewart/core/services';
import { policyWebUrlStorage } from '@stewart/core/services/policyWebUrlStorage.service';
import { scMainWebUrlStorage } from '@stewart/core/services/scMainWebUrlStorage.service';
import { useWindowSize } from '@stewart/core/utils/hooks.utils';

import PermissionRestrictedComponent from '../PermissionRestrictedComponent';

export const NAVIGATION_ITEMS: NavigationItemPropsType[] = [
  {
    title: label('lbl_users'),
    path: USERS_PATH,
    icon: PeopleIcon,
    permissionCode: PERMISSIONS.USERS_ACCESS,
  },
  {
    title: label('lbl_permissions'),
    path: PERMISSIONS_PATH,
    icon: KeyIcon,
    permissionCode: PERMISSIONS.PERMISSIONS_ACCESS,
  },
  {
    title: label('lbl_cache'),
    path: CACHE_PATH,
    icon: CachedIcon,
    permissionCode: PERMISSIONS.CACHE_ACCESS,
  },
  {
    title: label('lbl_logs'),
    path: LOGS_PATH,
    icon: FilePlusMinusIcon,
    permissionCode: PERMISSIONS.LOGS_ACCESS,
  },
  {
    title: label('lbl_type_tables'),
    path: TYPE_TABLES_PATH,
    icon: TableIcon,
    permissionCode: PERMISSIONS.TYPE_TABLE_ACCESS,
  },
  {
    title: label('lbl_companies'),
    path: COMPANIES_PATH,
    icon: BuildingIcon,
    permissionCode: PERMISSIONS.COMPANY_ACCESS,
  },
  {
    title: label('lbl_configuration'),
    path: CONFIGURATION_PATH,
    icon: SettingsIcon,
    permissionCode: PERMISSIONS.CONFIG_ADMIN_ACCESS,
  },
  {
    title: label('lbl_file_management'),
    path: FILE_MANAGEMENT_PATH,
    icon: FilePenIcon,
    permissionCode: PERMISSIONS.FILE_MANAGEMENT_ACCESS,
  },
  {
    title: label('lbl_uw_requests'),
    path: UWREQUEST_PATH,
    icon: FileDescriptionIcon,
    permissionCode: PERMISSIONS.UWA_ACCESS,
  },
  {
    title: label('lbl_policy_upload'),
    path: POLICY_UPLOAD_PATH,
    icon: UploadFileIcon,
    permissionCode: PERMISSIONS.UPLOADS_ADMIN_ACCESS,
  },
];

const StewartHeader = (): ReactJSXElement => {
  const classes = useHeaderStyles();

  const { displayName, firstName } = useSelector<RootStateType, ProfileDto>(
    (state) => state.profile
  );
  const { permissionCodes } = useSelector<RootStateType, PermissionStore>(
    (state) => state.permission
  );

  const history = useHistory();
  const { signOut } = useAuth();
  const isMobileMatch = useMediaQuery(`(max-width: ${mediaSize.minXlg}px)`);
  const isMobileView = useWindowSize() < mediaSize.minXlg;
  const [open, setOpen] = React.useState(false);
  const [openMyApps, setOpenMyApps] = React.useState(false);
  const editProfileDialogRef = useRef<EditProfileDialogRefType>(null);

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const anchorRefMyApps = React.useRef<HTMLButtonElement>(null);
  const [isMobileOpen, setMobileOpen] = useState<boolean>(false);

  const path = window.location.pathname;
  const title = NAVIGATION_ITEMS?.find?.((key) => key.path === path)?.title;
  const Icon = NAVIGATION_ITEMS?.find?.((key) => key.path === path)?.icon;

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };
  const handleCloseMyApps = (event: any) => {
    if (anchorRefMyApps.current && anchorRefMyApps.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMyApps(false);
  };
  /**
   * Moving to Home Page
   */
  function moveToHome(): void {
    history.push(ROOT_PATH);
  }

  /**
   * LogOut action
   */
  function logOut(): void {
    setOpen(false);
    signOut();
  }

  /**
   * Open Edit Profile dialog
   */
  function openEditProfile(): void {
    setOpen(false);
    editProfileDialogRef?.current?.open();
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    if (isMobileMatch || isMobileView) setMobileOpen(false);
  };
  const handleToggleMyApps = () => {
    setOpenMyApps((prevOpen) => !prevOpen);
    if (isMobileMatch || isMobileView) setMobileOpen(false);
  };
  /**
   * Open Help Center
   */
  function openHelpCenter(): void {
    setOpen(false);
    history.push(HELP_CENTER_PATH);
  }
  const showProfileDropdown = (
    <MaybeComponent isVisible={!!firstName}>
      <Box id="profileContainer" className={classes.profileContainer}>
        <Button className={classes.profile} ref={anchorRef} onClick={handleToggle} color="inherit">
          <Box className={classes.profileIconContainer}>
            <ProfileIcon />
          </Box>
          <Typography variant="subtitle1" className={`${classes.profileName} media`}>
            <StewartText maxRows={2}>{displayName}</StewartText>
          </Typography>
          {!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          disablePortal
          className={classes.menuContainer}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={100}>
              <>
                <Paper sx={{ maxWidth: '226px' }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      // autoFocusItem={open}
                      id="menu-appbar"
                      // onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={openEditProfile}>{label('lbl_profile_settings')}</MenuItem>
                      <PermissionRestrictedComponent
                        permissionCode={PERMISSIONS.HELP_CENTER_ACCESS}
                      >
                        <MenuItem onClick={openHelpCenter}>{label('lbl_help_center')}</MenuItem>
                      </PermissionRestrictedComponent>

                      <MenuItem onClick={logOut}>{label('lbl_logout')}</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </>
            </Fade>
          )}
        </Popper>
      </Box>
    </MaybeComponent>
  );
  /**
   * MyApps dropdown to allow the user to
   * go to SCMain Web or  Policy Web (if permissions are ok)
   */
  const scMainWebUrl: string = scMainWebUrlStorage.read();
  const policyWebUrl: string = policyWebUrlStorage.read();
  function goToSCMainWeb(): void {
    setOpenMyApps(false);
    window.open(scMainWebUrl, '_blank');
  }
  function goToPolicyWeb(): void {
    setOpenMyApps(false);
    window.open(policyWebUrl, '_blank');
  }
  let isMyAppsDropdownVisible = false;
  const enableSCMainLink = permissionCodes.includes(PERMISSIONS.SITE_ACCESS);
  const enablePolicyLink = permissionCodes.includes(PERMISSIONS.POLICY_ACCESS);
  if (enableSCMainLink || enablePolicyLink) isMyAppsDropdownVisible = true;
  const showMyAppsDropdown = (
    <MaybeComponent isVisible={isMyAppsDropdownVisible}>
      <Box id="profileContainer" className={classes.profileContainer}>
        <Button
          className={classes.profile}
          ref={anchorRefMyApps}
          onClick={handleToggleMyApps}
          color="inherit"
        >
          <Box className={classes.profileIconContainer}>
            <NineDotsIcon />
          </Box>
          <Typography variant="subtitle1" className={`${classes.profileName} media`}>
            <StewartText maxRows={2}>My Apps</StewartText>
          </Typography>
          {!openMyApps ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </Button>
        <Popper
          open={openMyApps}
          anchorEl={anchorRefMyApps.current}
          disablePortal
          className={classes.menuContainer}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={100}>
              <>
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseMyApps}>
                    <MenuList
                      // autoFocusItem={open}
                      id="menu-appbar"
                      // onKeyDown={handleListKeyDown}
                    >
                      {enableSCMainLink && (
                        <MenuItem onClick={goToSCMainWeb}>{label('lbl_connect_portal')}</MenuItem>
                      )}
                      {enablePolicyLink && (
                        <MenuItem onClick={goToPolicyWeb}>{label('lbl_policy_portal')}</MenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </>
            </Fade>
          )}
        </Popper>
      </Box>
    </MaybeComponent>
  );
  return (
    <AppBar position="sticky" className={`${classes.appbar} media`}>
      <Toolbar className={`${classes.toolbar} media`}>
        <Box className={classes.navigation} id="navBarLogo">
          <Button
            className={classes.logo}
            color="inherit"
            onClick={moveToHome}
            id="headerLogo"
            disableRipple={true}
          >
            <LogoIcon />
          </Button>
          {showMyAppsDropdown}
        </Box>
        {isMobileView || isMobileMatch ? (
          <>
            <MobileNavbar
              isMobileOpen={isMobileOpen}
              setMobileOpen={setMobileOpen}
              title={title}
              navIcon={<Icon />}
            />
            {showProfileDropdown}
          </>
        ) : (
          <>
            <Box className={classes.navigation} id="navBar">
              {NAVIGATION_ITEMS.filter((data: NavigationItemPropsType) =>
                permissionCodes.includes(data.permissionCode)
              ).map((data: NavigationItemPropsType): any => (
                <NavigationItem key={uuidv4()} {...data} />
              ))}
            </Box>
            {showProfileDropdown}
          </>
        )}
      </Toolbar>
      <EditProfileDialog ref={editProfileDialogRef} />
    </AppBar>
  );
};

export default StewartHeader;
