import React, { FC, ReactNode } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

type MaybeComponentPropsType = {
  children: ReactNode;
  isVisible: boolean;
};

// Component
const MaybeComponent: FC<MaybeComponentPropsType> = ({
  children,
  isVisible,
}: MaybeComponentPropsType): ReactJSXElement => {
  return <>{isVisible ? children : null}</>;
};

export default MaybeComponent;
