import * as yup from 'yup';

import { message } from '@stewart/core/services';

yup.setLocale({
  string: {
    max: ({ max }) => `Please enter no more than ${max} characters`,
  },
  mixed: {
    required: message('msg_field_is_required'),
  },
});

export default yup;
