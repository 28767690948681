import React, { createContext, ReactElement, useContext, useState } from 'react';

export type DeactivatedAccountContextType = {
  isDeactivatedAccount: boolean;
  setIsDeactivatedAccount: Function;
};

// Context
const DeactivatedAccountContext = createContext<DeactivatedAccountContextType>(
  {} as DeactivatedAccountContextType
);

// Provider
export function DeactivatedAccountProvider({ children }: { children: ReactElement }) {
  const [isDeactivatedAccount, setIsDeactivatedAccount] = useState<boolean>(false);

  return (
    <DeactivatedAccountContext.Provider value={{ isDeactivatedAccount, setIsDeactivatedAccount }}>
      {children}
    </DeactivatedAccountContext.Provider>
  );
}

export const useDeactivatedAccount = (): DeactivatedAccountContextType =>
  useContext<DeactivatedAccountContextType>(DeactivatedAccountContext);
