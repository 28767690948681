import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { PreloaderStore } from '@stewart/core/contexts/Preloader/models';
import {
  increasePreloaderAction,
  decreasePreloaderAction,
} from '@stewart/core/redux/actions/preloader';
import { RootStateType } from '@stewart/core/redux/redux.models';
import { CURRENT_BULK_INVITE_API_PATH } from '@stewart/core/rest/api-paths';

export type PreloaderContextType = {
  isOnPreloader: boolean;
  onPreloader: Function;
  offPreloader: Function;
};

const ACTIONS_IGNORE_LIST: string[] = [CURRENT_BULK_INVITE_API_PATH];

// Context
const PreloaderContext = createContext<PreloaderContextType>({} as PreloaderContextType);

// Provider
export function PreloaderProvider({ children }: { children: ReactElement }) {
  const dispatch = useDispatch();

  const [isOnPreloader, setIsOnPreloader] = useState<boolean>(false);

  const { count }: PreloaderStore = useSelector<RootStateType, PreloaderStore>(
    (state) => state.preloader
  );

  /**
   * Start Preloader Method
   */
  function onPreloader() {
    dispatch(increasePreloaderAction());
  }

  /**
   * Stop Preloader Method
   */
  function offPreloader() {
    dispatch(decreasePreloaderAction());
  }

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        const isIgnoreAction = ACTIONS_IGNORE_LIST.find((action: string) =>
          response.request.url?.match(action)
        );

        if (!isIgnoreAction) {
          offPreloader();
        }

        return response;
      },
      (error) => {
        offPreloader();
        return Promise.reject(error);
      }
    );

    axios.interceptors.request.use(
      async (request) => {
        const isIgnoreAction = ACTIONS_IGNORE_LIST.find((action: string) =>
          request.url?.match(action)
        );

        if (!isIgnoreAction) {
          onPreloader();
        }

        return request;
      },
      (error) => {
        offPreloader();
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    setIsOnPreloader(Boolean(count));
  }, [count]);

  return (
    <PreloaderContext.Provider value={{ isOnPreloader, onPreloader, offPreloader }}>
      {children}
    </PreloaderContext.Provider>
  );
}

export const usePreloader = (): PreloaderContextType =>
  useContext<PreloaderContextType>(PreloaderContext);
