export type DeclinationPropsType = {
  declinationInitialValues: DeclinationFieldsType;
};

export type DeclinationFieldsType = {
  declinationDate: string;
  declinedByName: string;
  declinationReason: string;
};

export const DECLINATION_INITIAL_VALUES = {
  declinationDate: '',
  declinedByName: '',
  declinationReason: '',
};
