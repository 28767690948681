import React, { ChangeEvent, CSSProperties, FC, useEffect, useState } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { flushSync } from 'react-dom';
import { GoogleChartWrapperChartType } from 'react-google-charts/dist/types';

import StewartActionBar from '@stewart/common-ui/components/StewartActionBar';
import StewartChart from '@stewart/common-ui/components/StewartCharts/StewartChart';
import { StewartChartElementType } from '@stewart/common-ui/components/StewartCharts/StewartChart/models';
import StewartSelect from '@stewart/common-ui/components/StewartSelect';
import {
  DashboardBasicCounts,
  FilesByState,
  ProductSummary,
  ProductSummaryByState,
} from '@stewart/core/rest/models/dashboard';
import { GridResponseType } from '@stewart/core/rest/models/grid.models';
import {
  getBasicCounts,
  getFileSummaryByState,
  getProductSummary,
  getProductSummaryByState,
} from '@stewart/core/rest/requests/dashboard.rest';
import { label, message } from '@stewart/core/services';
import { useHomePageStyles } from '@stewart/pages/HomePage/style';

const INITIAL_STATE = {
  period: 30,
};

const PERIODS = [{ value: 30 }, { value: 60 }, { value: 90 }];

// Component
const HomePage: FC = (): ReactJSXElement => {
  const classes = useHomePageStyles();
  const [basicCountData, setBasicCountData] = useState<StewartChartElementType[]>([]);
  const [filesByState, setFilesByState] = useState<StewartChartElementType[]>([]);
  const [productSummary, setProductSummary] = useState<StewartChartElementType[]>([]);
  const [productSummaryByState, setProductSummaryByState] = useState<StewartChartElementType[]>([]);
  const [dataColumnProductSummaryByState, setDataColumnProductSummaryByState] = useState<string[]>(
    []
  );

  const onSelect = () => {};

  const fillBasicCountChart = ({
    activeAgents,
    activeUsers,
    newFiles,
    newProducts,
    migratedFiles,
  }: DashboardBasicCounts): void => {
    setBasicCountData([
      { label: label('lbl_active_users'), value: activeUsers },
      { label: label('lbl_active_agents'), value: activeAgents },
      { label: label('lbl_new_files'), value: newFiles },
      { label: label('lbl_new_products'), value: newProducts },
      { label: label('lbl_migrated_files'), value: migratedFiles },
    ]);
  };

  const fillFilesByStateChart = (result: GridResponseType<FilesByState>): void => {
    setFilesByState(
      result.content.map(({ stateAbbr, count }: FilesByState) => ({
        label: stateAbbr,
        value: count,
      }))
    );
  };

  const fillProductSummaryChart = (result: GridResponseType<ProductSummary>): void => {
    setProductSummary(
      result.content.map(({ productTypeCode, count }: ProductSummary) => ({
        label: productTypeCode,
        value: count,
      }))
    );
  };

  const fillProductSummaryByStateChart = (
    result: GridResponseType<ProductSummaryByState>
  ): void => {
    const uniqStates = _.uniq(
      result.content.map(({ stateAbbr }: ProductSummaryByState) => stateAbbr)
    );
    const uniqProducts = _.uniq(
      result.content.map(({ productTypeCode }: ProductSummaryByState) => productTypeCode)
    );
    const data = uniqStates.map((state) => {
      return {
        label: state,
        value: uniqProducts
          .map((product) => {
            return _.sum(
              result.content
                .filter((f) => f.productTypeCode === product && f.stateAbbr === state)
                .map((f) => f.count)
            );
          })
          .map((s) => (s > 0 ? s : null)),
      };
    });
    flushSync(() => {
      setDataColumnProductSummaryByState(['', ...uniqProducts]);
      setProductSummaryByState(data);
    });
  };

  const invokeGetBasicCounts = (period: number): void => {
    getBasicCounts(period).then(fillBasicCountChart);
  };

  const invokeFilesByState = (period: number): void => {
    getFileSummaryByState(period).then(fillFilesByStateChart);
  };

  const invokeProductSummary = (period: number): void => {
    getProductSummary(period).then(fillProductSummaryChart);
  };

  const invokeProductSummaryByState = (period: number): void => {
    getProductSummaryByState(period).then(fillProductSummaryByStateChart);
  };

  useEffect(() => {
    invokeGetBasicCounts(30);
    invokeFilesByState(30);
    invokeProductSummary(30);
    invokeProductSummaryByState(30);
  }, []);

  const getChartItem = (
    title: string,
    data: StewartChartElementType[],
    placeholder: string,
    invokeMethod: (period: number) => void,
    chartType?: GoogleChartWrapperChartType,
    dataColumn?: string[],
    style?: CSSProperties,
    styleInner?: CSSProperties
  ) => {
    return (
      <Box className={classes.chartItem}>
        {data.length > 0 && (
          <StewartChart
            title={title}
            data={data}
            onSelect={onSelect}
            chartType={chartType}
            dataColumn={dataColumn}
            style={{ ...style, position: 'relative', zIndex: 1 }}
            innerComponent={
              <Box
                style={{
                  textAlign: 'center',
                  marginBottom: '-30px',
                  position: 'relative',
                  zIndex: 2,
                  ...styleInner,
                }}
              >
                <Formik enableReinitialize initialValues={INITIAL_STATE} onSubmit={() => {}}>
                  {({ handleChange }) => (
                    <Form>
                      <StewartSelect
                        controlStyle={{ maxWidth: '300px' }}
                        name="period"
                        placeholder={placeholder}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                          invokeMethod(parseInt(event.target.value, 10));
                          handleChange?.(event);
                        }}
                        options={PERIODS}
                        optionsTextField="value"
                        optionsValueField="value"
                      />
                    </Form>
                  )}
                </Formik>
              </Box>
            }
          />
        )}
      </Box>
    );
  };

  const getTableItem = (
    title: string,
    data: StewartChartElementType[],
    placeholder: string,
    invokeMethod: (period: number) => void
  ) => {
    return (
      <Box className={classes.chartItem} style={{ paddingBottom: '30px', width: '80%' }}>
        <Typography variant="h6" style={{ padding: '0 24px', textAlign: 'center' }}>
          {title}
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label={title}>
            <TableHead>
              <TableRow>
                <TableCell key="action">{label('lbl_period')}</TableCell>
                {data.map((item: StewartChartElementType) => (
                  <TableCell key={item.label}>{item.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  key="action"
                  style={{
                    backgroundColor: 'white',
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  <Formik enableReinitialize initialValues={INITIAL_STATE} onSubmit={() => {}}>
                    {({ handleChange }) => (
                      <Form>
                        <StewartSelect
                          controlStyle={{
                            maxWidth: '300px',
                            minWidth: '175px',
                            marginBottom: '16px',
                          }}
                          name="period"
                          placeholder={placeholder}
                          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                            invokeMethod(parseInt(event.target.value, 10));
                            handleChange?.(event);
                          }}
                          options={PERIODS}
                          optionsTextField="value"
                          optionsValueField="value"
                        />
                      </Form>
                    )}
                  </Formik>
                </TableCell>
                {data.map((item: StewartChartElementType) => (
                  <TableCell
                    style={{
                      backgroundColor: 'white',
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}
                    key={item.label}
                  >
                    {item.value}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return (
    <Box className={classes.dashboardContainer}>
      <StewartActionBar />
      <Box className={classes.chartContainer}>
        {getTableItem(
          message('msg_basic_counts'),
          basicCountData,
          label('lbl_select_basic_period'),
          invokeGetBasicCounts
        )}
      </Box>
      <Box className={classes.chartContainer}>
        <Box className={classes.chartItemFiles}>
          {getChartItem(
            message('msg_files_by_state'),
            filesByState,
            label('lbl_select_files_by_state_period'),
            invokeFilesByState,
            'ColumnChart',
            ['', ''],
            { width: '90vw', height: '350px' }
          )}
        </Box>
        {getChartItem(
          message('msg_product_summary'),
          productSummary,
          label('lbl_select_product_summary_period'),
          invokeProductSummary,
          'PieChart',
          ['', ''],
          { width: '40vw', height: '350px' }
        )}
        {getChartItem(
          message('msg_product_summary_by_state'),
          productSummaryByState,
          label('lbl_select_product_summary_by_state_period'),
          invokeProductSummaryByState,
          'BarChart',
          dataColumnProductSummaryByState,
          { width: '40vw', height: '750px' },
          { marginBottom: '-100px' }
        )}
      </Box>
    </Box>
  );
};

export default HomePage;
