import React, { FC, ReactNode } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useSelector } from 'react-redux';

import { PermissionStore } from '@stewart/core/redux/reducers/permission.reducer';
import { RootStateType } from '@stewart/core/redux/redux.models';

type PermissionRestrictedComponentPropsType = {
  children: ReactNode;
  permissionCode: string;
};

// Component
const PermissionRestrictedComponent: FC<PermissionRestrictedComponentPropsType> = ({
  children,
  permissionCode,
}: PermissionRestrictedComponentPropsType): ReactJSXElement => {
  const { permissionCodes } = useSelector<RootStateType, PermissionStore>(
    (state) => state.permission
  );

  return <>{permissionCodes.includes(permissionCode) ? children : null}</>;
};

export default PermissionRestrictedComponent;
