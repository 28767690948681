import { SchemaOf } from 'yup';

import { ConflictsOfInterestDto, TypeDto } from '@stewart/core/rest/models/uw-request.models';
import { message } from '@stewart/core/services';
import yup from '@stewart/core/utils/validation.utils';

export type NewMoreInfoPropsType = {
  moreInfoInitialValues: MoreInfoFormType;
  callbackForField: (partialData: Partial<MoreInfoFormType>) => void;
  types: TypeDto[];
  getApprovalLevelCalculation: (fieldName: string, value: string | number | boolean) => void;
  isFormDisabled: boolean;
  conflictsOfInterest: ConflictsOfInterestDto[];
  isReadOnly: boolean;
};

export type MoreInfoFormType = {
  transactionDescription?: string;
  propertyTypeDescription?: string;
  starterPriorCompany?: string;
  priorEffectiveDate?: string;
  policyTypeCode?: string;
  isLandAbutOpen?: boolean;
  conflictOfInterestTypeCode?: string;
  agentsOwingMoreThanTenPercent?: string;
  stateEndorsementList?: string;
  lastModifiedByName?: string;
  lastModifiedDate?: string;
  origCreatedByName?: string;
  origCreatedDate?: string;
  isReInsuranceRequired?: boolean;
  reInsuranceDescription?: string;
  isMultiState?: boolean;
  multiStateDescription?: string;
  isMultiSite?: boolean;
  multiSiteDescription?: string;
  isAggregateAmount?: boolean;
  aggregateAmountDescription?: string;
  hasCoInsurance?: boolean;
  coInsuranceDescription?: string;
};

export const moreInfoSchema: SchemaOf<MoreInfoFormType> = yup.object({
  transactionDescription: yup.string().optional().max(2000),
  propertyTypeDescription: yup.string().optional().max(2000),
  starterPriorCompany: yup.string().optional().max(50),
  priorEffectiveDate: yup.string().optional(),
  policyTypeCode: yup.string().optional(),
  isLandAbutOpen: yup.boolean(),
  conflictOfInterestTypeCode: yup.string().optional(),
  agentsOwingMoreThanTenPercent: yup.string().optional().max(50),
  stateEndorsementList: yup.string().optional().max(2000),
  lastModifiedByName: yup.string().optional(),
  lastModifiedDate: yup.string().optional(),
  origCreatedByName: yup.string().optional(),
  origCreatedDate: yup.string().optional(),
  isReInsuranceRequired: yup.boolean().optional(),
  isMultiState: yup.boolean().optional(),
  isMultiSite: yup.boolean().optional(),
  isAggregateAmount: yup.boolean().optional(),
  hasCoInsurance: yup.boolean().optional(),
  reInsuranceDescription: yup
    .string()
    .test('isRequired', message('msg_field_is_required'), (value, scope) => {
      if (!scope.parent.isReInsuranceRequired) {
        return true;
      }
      return !!value;
    })
    .trim()
    .max(2000),
  multiStateDescription: yup
    .string()
    .test('isRequired', message('msg_field_is_required'), (value, scope) => {
      if (!scope.parent.isMultiState) {
        return true;
      }
      return !!value;
    })
    .trim()
    .max(2000),
  multiSiteDescription: yup
    .string()
    .test('isRequired', message('msg_field_is_required'), (value, scope) => {
      if (!scope.parent.isMultiSite) {
        return true;
      }
      return !!value;
    })
    .trim()
    .max(2000),
  aggregateAmountDescription: yup
    .string()
    .test('isRequired', message('msg_field_is_required'), (value, scope) => {
      if (!scope.parent.isAggregateAmount) {
        return true;
      }
      return !!value;
    })
    .trim()
    .max(2000),
  coInsuranceDescription: yup
    .string()
    .test('isRequired', message('msg_field_is_required'), (value, scope) => {
      if (!scope.parent.hasCoInsurance) {
        return true;
      }
      return !!value;
    })
    .trim()
    .max(2000),
});

export const MORE_INFO_INITIAL_VALUES: MoreInfoFormType = {
  transactionDescription: '',
  propertyTypeDescription: '',
  starterPriorCompany: '',
  priorEffectiveDate: '',
  policyTypeCode: '',
  isLandAbutOpen: false,
  conflictOfInterestTypeCode: '',
  agentsOwingMoreThanTenPercent: '',
  stateEndorsementList: '',
  lastModifiedByName: '',
  lastModifiedDate: '',
  origCreatedByName: '',
  origCreatedDate: '',
  isReInsuranceRequired: false,
  reInsuranceDescription: '',
  isMultiState: false,
  multiStateDescription: '',
  isMultiSite: false,
  multiSiteDescription: '',
  isAggregateAmount: false,
  aggregateAmountDescription: '',
  hasCoInsurance: false,
  coInsuranceDescription: '',
};
