import React, { useEffect, useRef } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import axios, { AxiosResponse } from 'axios';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import StewartNotifyDialog from '@stewart/common-ui/dialogs/StewartNotifyDialog';
import { StewartNotifyDialogRefType } from '@stewart/common-ui/dialogs/StewartNotifyDialog/models';
import { useAuth } from '@stewart/core/contexts/Auth';
import { useDeactivatedAccount } from '@stewart/core/contexts/DeactivatedAccount';
import { ErrorType } from '@stewart/core/models';
import { DEACTIVATED_ACCOUNT_PATH } from '@stewart/core/routing/routes/outside.routes';
import {
  POLICY_UPLOAD_SAVE_DOCUMENT_SUPPLEMENTAL_API_PATH,
  POLICY_UPLOAD_EXPORT_API_PATH,
} from '@stewart/core/rest/api-paths';

const DEACTIVATED_ACCOUNT_CODE_ERROR: number = 403;
const LOGGED_OUT_CODE_ERROR: number = 401;
const IGNORE_API_PATH_LIST: string[] = [
  POLICY_UPLOAD_SAVE_DOCUMENT_SUPPLEMENTAL_API_PATH,
  POLICY_UPLOAD_EXPORT_API_PATH,
];

export default function ErrorInterceptor(): ReactJSXElement {
  const history = useHistory();
  const { signOut } = useAuth();
  const { setIsDeactivatedAccount } = useDeactivatedAccount();
  const stewartNotifyDialogRef = useRef<StewartNotifyDialogRefType>(null);

  useEffect(() => {
    axios.interceptors.response.use(
      (response: AxiosResponse): AxiosResponse => {
        setIsDeactivatedAccount(false);
        return response;
      },
      (error): Promise<ErrorType> => {
        if (axios.isCancel(error)) {
          return new Promise((resolve) => resolve);
        }
        if (error.response) {
          if (error.response.status === LOGGED_OUT_CODE_ERROR) {
            signOut();
          }

          if (error.response.status === DEACTIVATED_ACCOUNT_CODE_ERROR) {
            setIsDeactivatedAccount(true);
            history.push(DEACTIVATED_ACCOUNT_PATH);

            return Promise.reject(error);
          }
        }

        const ignoreApiPath = IGNORE_API_PATH_LIST?.find((apiPath: string) =>
          error.response?.config?.url?.match(apiPath)
        );

        if (!ignoreApiPath) {
          if (!error.response?.data?.validationErrors?.length) {
            stewartNotifyDialogRef.current?.open(
              'error',
              error.response?.data?.messages ??
                [error.toString()].map((text) => <div key={uuidv4()}>{text}</div>)
            );
          }
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      <StewartNotifyDialog
        onCancel={() => {
          stewartNotifyDialogRef.current?.close();
        }}
        ref={stewartNotifyDialogRef}
      />
    </>
  );
}
