import App from '@stewart/app/App';
import HealthCheckComponent from '@stewart/common-ui/components/HealthCheckComponent';
import { RouteType } from '@stewart/core/routing/models';
import DeactivatedAccountPage from '@stewart/pages/DeactivatedAccountPage';

// Routes' paths constants
export const HEALTH_CHECK_PATH: string = `/healthCheck`;
export const DEACTIVATED_ACCOUNT_PATH: string = `/deactivated`;

// Routes config
export const OUTSIDE_ROUTES: RouteType[] = [
  {
    path: HEALTH_CHECK_PATH,
    exact: true,
    component: HealthCheckComponent,
  },
  {
    path: DEACTIVATED_ACCOUNT_PATH,
    exact: true,
    component: DeactivatedAccountPage,
  },
  {
    path: '*',
    component: App,
  },
];
