import yup from '@stewart/core/utils/validation.utils';

import { MoreInfoFormType } from '../NewMoreInfo/models';

export type SpecialRisksComponentPropsType = {
  specialRisksInitialValues: SpecialRisksFormType;
  callbackForField: (partialData: Partial<SpecialRisksFormType>) => void;
  getApprovalLevelCalculation: (fieldName: string, value: string | number | boolean) => void;
  isFormDisabled: boolean;
  isReadOnly: boolean;
};

export type SpecialRisksFormType = {
  isConstructionLoan?: boolean;
  isBrokenPriority?: boolean;
  brokenPriorityDescription?: string;
  isRecentConstruction?: boolean;
  recentConstructionDescription?: string;
  hasMechanicsLienCoverageRequest?: boolean;
  isTidelands?: boolean;
  isRailroadProperty?: boolean;
  isJudicialProceeding?: boolean;
  isForeclosure?: boolean;
  hasMineralCoverage?: boolean;
  isLoanConstructionForeclosure?: boolean;
  isEasementIndependantOfProperty?: boolean;
  hasInsuredOption?: boolean;
  isNativeAmericanLand?: boolean;
  hasWaterRights?: boolean;
  hasSheriffSale?: boolean;
  isInsuringRecordedLien?: boolean;
  insuringRecordedLienDescription?: string;
  isAssignmentOfInterest?: boolean;
  assignmentOfMortangeDescription?: string;
  hasRelianceOnIndemnity?: boolean;
  purposeDescription?: string;
  hasSurveyConcerns?: boolean;
  isOwnerOutOfPossession?: boolean;
  surveyConcernsDescription?: string;
  hasPurchaseContract?: boolean;
  hasOtherExtraHazardRisk?: boolean;
  extraHazardRiskDescription?: string;
  hasLitigation?: boolean;
  hasTransactionTurnedDown?: boolean;
  isEnergyProject?: boolean;
  isCannabisProject?: boolean;
  isHardMoney?: boolean;
  hasUnusualCoverages?: boolean;
  unusualCoverages?: string;
  hasCoInsurance?: boolean;
  coInsuranceDescription?: string;
  lastModifiedByName?: string;
  lastModifiedDate?: string;
  origCreatedByName?: string;
  origCreatedDate?: string;
};

export const SPECIAL_RISKS_INITIAL_VALUES: SpecialRisksFormType = {
  isConstructionLoan: false,
  isBrokenPriority: false,
  brokenPriorityDescription: '',
  isRecentConstruction: false,
  recentConstructionDescription: '',
  hasMechanicsLienCoverageRequest: false,
  isTidelands: false,
  isRailroadProperty: false,
  isJudicialProceeding: false,
  isForeclosure: false,
  hasMineralCoverage: false,
  isLoanConstructionForeclosure: false,
  isEasementIndependantOfProperty: false,
  hasInsuredOption: false,
  isNativeAmericanLand: false,
  hasWaterRights: false,
  hasSheriffSale: false,
  isInsuringRecordedLien: false,
  insuringRecordedLienDescription: '',
  isAssignmentOfInterest: false,
  assignmentOfMortangeDescription: '',
  hasRelianceOnIndemnity: false,
  purposeDescription: '',
  hasSurveyConcerns: false,
  isOwnerOutOfPossession: false,
  surveyConcernsDescription: '',
  hasPurchaseContract: false,
  hasOtherExtraHazardRisk: false,
  extraHazardRiskDescription: '',
  hasLitigation: false,
  hasTransactionTurnedDown: false,
  isEnergyProject: false,
  isCannabisProject: false,
  isHardMoney: false,
  hasUnusualCoverages: false,
  unusualCoverages: '',
  hasCoInsurance: false,
  coInsuranceDescription: '',
  lastModifiedByName: '',
  lastModifiedDate: '',
  origCreatedByName: '',
  origCreatedDate: '',
};

export const specialRisksSchema = yup.object({
  isConstructionLoan: yup.boolean(),
  isBrokenPriority: yup.boolean(),
  brokenPriorityDescription: yup
    .string()
    .when('isBrokenPriority', {
      is: true,
      then: yup.string().required(),
    })
    .max(2000),
  isRecentConstruction: yup.boolean(),
  recentConstructionDescription: yup
    .string()
    .when('isRecentConstruction', {
      is: true,
      then: yup.string().required(),
    })
    .max(2000),
  hasMechanicsLienCoverageRequest: yup.boolean(),
  isTidelands: yup.boolean(),
  isRailroadProperty: yup.boolean(),
  isJudicialProceeding: yup.boolean(),
  isForeclosure: yup.boolean(),
  hasMineralCoverage: yup.boolean(),
  isLoanConstructionForeclosure: yup.boolean(),
  isEasementIndependantOfProperty: yup.boolean(),
  hasInsuredOption: yup.boolean(),
  isNativeAmericanLand: yup.boolean(),
  hasWaterRights: yup.boolean(),
  hasSheriffSale: yup.boolean(),
  isInsuringRecordedLien: yup.boolean(),
  insuringRecordedLienDescription: yup
    .string()
    .when('isInsuringRecordedLien', {
      is: true,
      then: yup.string().required(),
    })
    .max(2000),
  isAssignmentOfInterest: yup.boolean(),
  assignmentOfMortangeDescription: yup
    .string()
    .when('isAssignmentOfInterest', {
      is: true,
      then: yup.string().required(),
    })
    .max(2000),
  hasRelianceOnIndemnity: yup.boolean(),
  purposeDescription: yup
    .string()
    .when('hasRelianceOnIndemnity', {
      is: true,
      then: yup.string().required(),
    })
    .max(2000),
  hasSurveyConcerns: yup.boolean(),
  isOwnerOutOfPossession: yup.boolean(),
  surveyConcernsDescription: yup
    .string()
    .when('hasSurveyConcerns', {
      is: true,
      then: yup.string().required(),
    })
    .max(2000),
  hasPurchaseContract: yup.boolean(),
  hasOtherExtraHazardRisk: yup.boolean(),
  extraHazardRiskDescription: yup
    .string()
    .when('hasOtherExtraHazardRisk', {
      is: true,
      then: yup.string().required(),
    })
    .max(2000),
  hasLitigation: yup.boolean(),
  hasTransactionTurnedDown: yup.boolean(),
  isEnergyProject: yup.boolean(),
  isCannabisProject: yup.boolean(),
  isHardMoney: yup.boolean(),
  hasUnusualCoverages: yup.boolean(),
  unusualCoverages: yup
    .string()
    .when('hasUnusualCoverages', {
      is: true,
      then: yup.string().required(),
    })
    .max(2000),
  hasCoInsurance: yup.boolean(),
  coInsuranceDescription: yup
    .string()
    .when('hasCoInsurance', {
      is: true,
      then: yup.string().required(),
    })
    .max(2000),
  lastModifiedByName: yup.string().optional(),
  lastModifiedDate: yup.string().optional(),
  origCreatedByName: yup.string().optional(),
  origCreatedDate: yup.string().optional(),
});

export type MoreInfoAndSpecialRisksDto = SpecialRisksFormType & MoreInfoFormType;
