import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

type StewartActionBarPropsType = {
  children?: ReactNode;
};

// Component
const StewartActionBar = ({ children }: StewartActionBarPropsType) => {
  return (
    <Box
      style={{
        display: 'grid',
        columnGap: '8px',
        gridAutoFlow: 'column',
        justifyContent: 'end',
        padding: '24px 33px',
      }}
    >
      {children}
    </Box>
  );
};

StewartActionBar.defaultProps = {
  children: [],
};

export default StewartActionBar;
