import React, { useEffect, useRef } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import StewartDialog from '@stewart/common-ui/dialogs/StewartDialog';
import { StewartDialogRefType } from '@stewart/common-ui/dialogs/StewartDialog/models';
import TickIcon from '@stewart/common-ui/icons/TickIcon';
import { useInfoDialogs } from '@stewart/core/contexts/InfoDialogs';
import { label } from '@stewart/core/services';

// Component
const StewartErrorDialog = () => {
  const { openErrorDialog, setOpenErrorDialog, errorMessages } = useInfoDialogs();
  const stewartDialogRef = useRef<StewartDialogRefType>(null);

  const onCloseCallback = () => {
    setOpenErrorDialog(false);
  };
  const closeDialog = () => {
    onCloseCallback();
    stewartDialogRef?.current?.close();
  };

  const isOpen = !stewartDialogRef?.current?.isOpened() && openErrorDialog;

  useEffect(() => {
    if (isOpen) stewartDialogRef?.current?.open();
  }, [isOpen]);

  function contentBlock(): ReactJSXElement {
    return (
      <Box style={{ maxWidth: '28vw' }}>
        {errorMessages.map((text) => (
          <div key={uuidv4()}>{text}</div>
        ))}
      </Box>
    );
  }

  function actionsBlock(): ReactJSXElement {
    return (
      <Box>
        <Button
          style={{ minWidth: '120px' }}
          variant="contained"
          onClick={closeDialog}
          startIcon={<TickIcon />}
        >
          {label('lbl_ok')}
        </Button>
      </Box>
    );
  }

  return (
    <>
      <StewartDialog
        title={label('lbl_error')}
        ref={stewartDialogRef}
        contentBlock={contentBlock}
        actionsBlock={actionsBlock}
        onCloseCallback={onCloseCallback}
        type="error"
      />
    </>
  );
};

export default StewartErrorDialog;
