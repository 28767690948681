import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import initRootReducer from '@stewart/core/redux/reducers/root.reducer';

/**
 * Initialization Store
 * @param {any} history
 */
export default function initStore(history: any) {
  return createStore(
    initRootReducer(history),
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
  );
}
