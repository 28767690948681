import { httpRequests } from '@stewart/core/http';
import {
  GET_COUNTIES_API_PATH,
  GET_LOGIN_TYPES_API_PATH,
  GET_TIMEZONE_LIST_API_PATH,
  GET_USER_STATUS_TYPES_API_PATH,
  GET_USER_TYPES_API_PATH,
} from '@stewart/core/rest/api-paths';
import { TimeZoneDto } from '@stewart/core/rest/models/lookup.models';
import { LoginTypeDto, UserStatusType, UserTypeDto } from '@stewart/core/rest/models/user.models';
import { CountyDto } from '@stewart/core/rest/models/uw-request.models';

/**
 * Return List of Stewart TimeZones
 * @return {Promise<TimeZoneDto[]>}
 */
export function getTimeZoneList(): Promise<TimeZoneDto[]> {
  return httpRequests.get(GET_TIMEZONE_LIST_API_PATH);
}

/**
 * Return Stewart Login Type List
 * @return {Promise<LoginTypeDto[]>}
 */
export function getLoginTypes(): Promise<LoginTypeDto[]> {
  return httpRequests.get(GET_LOGIN_TYPES_API_PATH);
}

/**
 * Return Stewart User Type List
 * @return {Promise<UserTypeDto[]>}
 */
export function getUserTypes(): Promise<UserTypeDto[]> {
  return httpRequests.get(GET_USER_TYPES_API_PATH);
}

/**
 * Return Stewart User Type List
 * @return {Promise<UserStatusType[]>}
 */
export function getUserStatusTypes(): Promise<UserStatusType[]> {
  return httpRequests.get(GET_USER_STATUS_TYPES_API_PATH);
}

/**
 * Return Counties List
 * @param {string} stateAbbr
 * @return {Promise<CountyDto[]>}
 */
export function getCounties(stateAbbr: string): Promise<CountyDto[]> {
  return httpRequests.get(GET_COUNTIES_API_PATH.replace('{stateAbbr}', stateAbbr));
}
