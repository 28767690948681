import { Dispatch } from 'redux';

import { SET_PERMISSION_ACTION } from '@stewart/core/redux/types/permission.type';
import { PermissionItemTypeReadModel } from '@stewart/core/rest/models/user.models';
import { getPermissionItemsCurrentUser } from '@stewart/core/rest/requests/users.rest';

/**
 * Increase count
 */
export function setPermissionAction(permissionCodes: string[]) {
  return { type: SET_PERMISSION_ACTION, payload: permissionCodes };
}

/**
 * Get and Save to Store Permission Code List
 * @return {Function}
 */
export function getPermissionCodesListAsyncAction(): Function {
  return async (dispatch: Dispatch<any>) => {
    const permissionCodes: string[] = (await getPermissionItemsCurrentUser()).map(
      (item: PermissionItemTypeReadModel) => item.permissionItemTypeCode
    );
    dispatch({ type: SET_PERMISSION_ACTION, payload: permissionCodes });
  };
}
