import React from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import { useFooterStyles } from '@stewart/common-ui/components/StewartFooter/style';
import { message } from '@stewart/core/services';

export default function StewartFooter(): ReactJSXElement {
  const classes = useFooterStyles();
  const getCurrentYear = () => new Date().getFullYear();
  return (
    <footer className={classes.footer}>
      <div className={classes.copyright}>
        © {getCurrentYear()} {message('msg_stewart_title_guaranty_company')}
        <span>{message('msg_trademarks_are_the_property_of_their_respective_owners')}</span>
        <br />
      </div>
      <div className={classes.links}>
        <a
          href="http://www.stewart.com/en/privacy.html"
          className={classes.link}
          rel="noreferrer"
          target="_blank"
        >
          {message('msg_privacy_policy')}
        </a>
        <a
          href="http://www.stewart.com/en/terms-of-use.html"
          className={classes.link}
          rel="noreferrer"
          target="_blank"
        >
          {message('msg_terms_of_use')}
        </a>
        <a
          className={classes.link}
          href="http://www.stewart.com/en/protecting-customer-information.html#st-mainParsys_generictextcomponent"
          rel="noreferrer"
          target="_blank"
        >
          {message('msg_protecting_customer_information')}
        </a>
      </div>
    </footer>
  );
}
