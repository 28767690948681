import React, { ReactNode } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import { RouterType, RouteType } from '@stewart/core/routing/models';

import { PermissionStore } from '../redux/reducers/permission.reducer';
import { RootStateType } from '../redux/redux.models';
import { DEACTIVATED_ACCOUNT_PATH } from './routes/outside.routes';

const Router = ({ routes }: RouterType): ReactJSXElement => {
  const { permissionCodes } = useSelector<RootStateType, PermissionStore>(
    (state) => state.permission
  );

  return (
    <Switch>
      {routes.map((route: RouteType) => {
        const { path, component: Component, exact, redirect, permissionCode } = route;
        const hasPermission = permissionCode && permissionCodes.includes(permissionCode);
        return (
          <Route
            key={path}
            path={path}
            exact={exact}
            render={(props: any): ReactNode => {
              if (redirect) {
                return <Redirect to={redirect} />;
              }
              if (!permissionCode || hasPermission) return <Component {...props} />;

              return <Redirect to={DEACTIVATED_ACCOUNT_PATH} />;
            }}
          />
        );
      })}
    </Switch>
  );
};

export default Router;
