import { ActionType } from '@stewart/core/redux/redux.models';
import { GET_PROFILE_ACTION } from '@stewart/core/redux/types/profile.type';
import { ProfileDto } from '@stewart/core/rest/models/user.models';

const INITIAL_STATE: ProfileDto = {
  displayName: '',
  firstName: '',
  lastName: '',
  timeZone: '',
  userID: '',
};

export function profileReducer(state: ProfileDto = INITIAL_STATE, action: ActionType): ProfileDto {
  switch (action.type) {
    case GET_PROFILE_ACTION:
      return action.payload;
    default:
      return state;
  }
}
