const TOKEN_KEY: string = 'stewart_grid_';

const getFullTokenKey = (gridName: string) => `${TOKEN_KEY}${gridName}`;

export const gridStorage = {
  write: (gridName: string, value: string) =>
    sessionStorage.setItem(getFullTokenKey(gridName), value),
  read: (gridName: string) => sessionStorage.getItem(getFullTokenKey(gridName)) || '',
  remove: (gridName: string) => sessionStorage.removeItem(getFullTokenKey(gridName)),
};
