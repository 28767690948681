import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { margin } from '@stewart/theme';

interface Props {
  children?: ReactNode;
}
// Create a styled version of the MUI Box component
const StyledBox = styled(Box)(() => ({
  marginRight: margin.xlarge3,
  marginLeft: margin.xlarge3,
}));

const StyledRootBox = ({ children }: Props) => {
  return <StyledBox>{children}</StyledBox>;
};

export default StyledRootBox;
