import React from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import ErrorInterceptor from '@stewart/core/http/interceptors/ErrorInterceptor';
import JwtInterceptor from '@stewart/core/http/interceptors/JwtInterceptor';
import { InterceptorsType } from '@stewart/core/models';

export default function Interceptors({
  children,
  subscriptionKey,
}: InterceptorsType): ReactJSXElement {
  return (
    <>
      <ErrorInterceptor />
      <JwtInterceptor subscriptionKey={subscriptionKey} />
      {children}
    </>
  );
}
