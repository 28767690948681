import React, { useEffect } from 'react';

import { Box } from '@mui/material';

import { checkAPIMethod } from '@stewart/core/rest/requests/users.rest';
import { label } from '@stewart/core/services';
import { errorHandler } from '@stewart/core/utils/helper.utils';

function HealthCheckComponent() {
  useEffect(() => {
    checkAPIMethod().catch(errorHandler);
  });

  return <Box>{label('lbl_healthy')}</Box>;
}

export default HealthCheckComponent;
