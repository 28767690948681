import { Theme } from '@mui/material';

import { colors } from '@stewart/theme/index';
import { makeStyles } from '@mui/styles';

export const useStewartInputStyles = makeStyles<Theme>(() => ({
  warning: {
    '& fieldset': {
      borderColor: `${colors.orange}!important`,
    },
    '&.warning-input:hover fieldset': {
      borderColor: `${colors.orange}!important`,
    },
  },
  error: {
    marginLeft: '12px!important',
    position: 'absolute',
    top: '35px',
    maxHeight: '30px',
    overflowY: 'auto',
  },
  label: {
    // backgroundColor: 'white',
    padding: '0px 8px',
  },
  errorLabel: { color: 'rgb(224, 0, 0) !important' },
}));
