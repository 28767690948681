export type CancellationInfoPropsType = { cancellationInitialValues: CancellationFieldsType };
export type CancellationFieldsType = {
  cancellationDate: string;
  cancelledByName: string;
  cancellationReason: string;
};

export const CANCELLATION_INITIAL_VALUES = {
  cancellationDate: '',
  cancelledByName: '',
  cancellationReason: '',
};
