import { PreloaderStore } from '@stewart/core/contexts/Preloader/models';
import { ActionType } from '@stewart/core/redux/redux.models';
import {
  DECREASE_PRELOADER_ACTION,
  INCREASE_PRELOADER_ACTION,
} from '@stewart/core/redux/types/preloader.type';

const INITIAL_STATE: PreloaderStore = {
  count: 0,
};

export function preloaderReducer(
  state: PreloaderStore = INITIAL_STATE,
  action: ActionType
): PreloaderStore {
  switch (action.type) {
    case INCREASE_PRELOADER_ACTION:
      return { count: state.count + 1 };
    case DECREASE_PRELOADER_ACTION:
      return { count: state.count > 0 ? state.count - 1 : 0 };
    default:
      return state;
  }
}
