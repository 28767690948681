import React from 'react';

const SettingsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M19.14 12.9399C19.18 12.6399 19.2 12.3299 19.2 11.9999C19.2 11.6799 19.18 11.3599 19.13 11.0599L21.16 9.4799C21.34 9.3399 21.39 9.0699 21.28 8.8699L19.36 5.5499C19.24 5.3299 18.99 5.2599 18.77 5.3299L16.38 6.2899C15.88 5.9099 15.35 5.5899 14.76 5.3499L14.4 2.8099C14.36 2.5699 14.16 2.3999 13.92 2.3999H10.08C9.84 2.3999 9.65 2.5699 9.61 2.8099L9.25 5.3499C8.66 5.5899 8.12 5.9199 7.63 6.2899L5.24 5.3299C5.02 5.2499 4.77 5.3299 4.65 5.5499L2.74 8.8699C2.62 9.0799 2.66 9.3399 2.86 9.4799L4.89 11.0599C4.84 11.3599 4.8 11.6899 4.8 11.9999C4.8 12.3099 4.82 12.6399 4.87 12.9399L2.84 14.5199C2.66 14.6599 2.61 14.9299 2.72 15.1299L4.64 18.4499C4.76 18.6699 5.01 18.7399 5.23 18.6699L7.62 17.7099C8.12 18.0899 8.65 18.4099 9.24 18.6499L9.6 21.1899C9.65 21.4299 9.84 21.5999 10.08 21.5999H13.92C14.16 21.5999 14.36 21.4299 14.39 21.1899L14.75 18.6499C15.34 18.4099 15.88 18.0899 16.37 17.7099L18.76 18.6699C18.98 18.7499 19.23 18.6699 19.35 18.4499L21.27 15.1299C21.39 14.9099 21.34 14.6599 21.15 14.5199L19.14 12.9399ZM12 15.5999C10.02 15.5999 8.4 13.9799 8.4 11.9999C8.4 10.0199 10.02 8.3999 12 8.3999C13.98 8.3999 15.6 10.0199 15.6 11.9999C15.6 13.9799 13.98 15.5999 12 15.5999Z"
        fill="white"
      />
    </svg>
  );
};

export default SettingsIcon;
