import React, { FC } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Button, Typography } from '@mui/material';
import { useHistory } from 'react-router';

import { useDeactivatedAccount } from '@stewart/core/contexts/DeactivatedAccount';
import { getProfile } from '@stewart/core/rest/requests/users.rest';
import { ROOT_PATH } from '@stewart/core/routing/routes/root.routes';
import { label } from '@stewart/core/services';

// Component
const DeactivatedAccountPage: FC = (): ReactJSXElement => {
  const history = useHistory();
  const { setIsDeactivatedAccount } = useDeactivatedAccount();

  /**
   * Check is Deactivate User
   */
  function reloadHandler(): void {
    getProfile().then(() => {
      setIsDeactivatedAccount(false);
      history.push(ROOT_PATH);
    });
  }

  return (
    <>
      <Box
        style={{
          fontWeight: 500,
          fontSize: '24px',
          color: '#000000',
          textAlign: 'center',
          marginTop: 'calc(50vh - 230px)',
        }}
      >
        <Typography variant="h5" style={{ fontSize: '24px' }}>
          There is a problem with your account.
          <br />
          Please contact the Agency Support Center for assistance.
          <br />
          <br />
        </Typography>

        <Box>
          <Typography variant="h3" style={{ fontSize: '24px' }}>
            Agency Support Center
          </Typography>
          <br />

          <a href="tel:(844) 835-1200" style={{ textDecoration: 'none' }}>
            (844) 835-1200
          </a>
          <br />

          <a href="mailto:CustomerCare@Stewart.com" style={{ textDecoration: 'none' }}>
            CustomerCare@Stewart.com
          </a>
        </Box>
        <br />
        <br />
        <br />

        <Button style={{ minWidth: '200px' }} onClick={reloadHandler} variant="contained">
          {label('lbl_reload')}
        </Button>
      </Box>
    </>
  );
};

export default DeactivatedAccountPage;
