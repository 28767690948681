import { ActionType } from '@stewart/core/redux/redux.models';
import { SET_PERMISSION_ACTION } from '@stewart/core/redux/types/permission.type';

export type PermissionStore = {
  permissionCodes: string[];
};

const INITIAL_STATE: PermissionStore = {
  permissionCodes: [],
};

export function permissionReducer(
  state: PermissionStore = INITIAL_STATE,
  { type, payload }: ActionType
): PermissionStore {
  switch (type) {
    case SET_PERMISSION_ACTION:
      return { permissionCodes: payload };
    default:
      return state;
  }
}
