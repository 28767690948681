export type EditCompanySignatureDialogPropsType = {
  onClose: () => void;
};

export type EditCompanySignatureDialogRefType = {
  open: (companyID: string, id?: string) => Promise<void>;
};

export enum InitialValue {
  ALL_VALUE = '-ALL-',
  JPG_VALUE = 'JPG',
  BASE_64_META = 'data:image/jpeg;base64,',
}
