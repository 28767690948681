import React, { forwardRef, Ref, useImperativeHandle, useRef } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box } from '@mui/material';
import { FormikHandlers, FormikHelpers } from 'formik/dist/types';
import { useDispatch, useSelector } from 'react-redux';

import StewartCancelButton from '@stewart/common-ui/components/ActionButtons/StewartCancelButton';
import StewartSaveOrConfirmButton from '@stewart/common-ui/components/ActionButtons/StewartSaveOrConfirmButton';
import { PERMISSIONS } from '@stewart/common-ui/components/AuthenticatedApp/utils';
import PermissionRestrictedComponent from '@stewart/common-ui/components/PermissionRestrictedComponent';
import StewartInput from '@stewart/common-ui/components/StewartInput';
import StewartSelect from '@stewart/common-ui/components/StewartSelect';
import {
  EditProfileDialogPropsType,
  EditProfileDialogRefType,
  InitialEditProfileValuesType,
} from '@stewart/common-ui/dialogs/EditProfileDialog/models';
import StewartDialogWithConfirmationChanges from '@stewart/common-ui/dialogs/StewartDialogWithConfirmationChanges';
import { StewartDialogWithConfirmRefType } from '@stewart/common-ui/dialogs/StewartDialogWithConfirmationChanges/models';
import { useComponentRefs } from '@stewart/core/contexts/ComponentRefs';
import { useInfoDialogs } from '@stewart/core/contexts/InfoDialogs';
import {
  getProfileAsyncAction,
  updateProfileAsyncAction,
} from '@stewart/core/redux/actions/profile.action';
import { RootStateType } from '@stewart/core/redux/redux.models';
import { TimeZoneDto } from '@stewart/core/rest/models/lookup.models';
import { CreateUserBody, ProfileDto } from '@stewart/core/rest/models/user.models';
import { label, message } from '@stewart/core/services';
import { setFormErrors } from '@stewart/core/utils/helper.utils';
import yup from '@stewart/core/utils/validation.utils';

// Validation Setting
const validationSchema = yup.object({
  displayName: yup
    .string()
    .required('Field is required')
    .trim(message('msg_the_field_can_not_include_leading_and_trailing_spaces'))
    .max(254),
  firstName: yup
    .string()
    .required('Field is required')
    .trim(message('msg_the_field_can_not_include_leading_and_trailing_spaces'))
    .max(50),
  lastName: yup
    .string()
    .required('Field is required')
    .trim(message('msg_the_field_can_not_include_leading_and_trailing_spaces'))
    .max(100),
  timeZone: yup.string().required('Field is required'),
});

// Initial Values
const INITIAL_VALUES: InitialEditProfileValuesType = {
  displayName: '',
  firstName: '',
  lastName: '',
  timeZone: '',
};

// Component
const EditProfileDialog = (
  props: EditProfileDialogPropsType,
  ref: Ref<EditProfileDialogRefType>
) => {
  const profileInfo: ProfileDto = useSelector<RootStateType, ProfileDto>((state) => state.profile);
  const timeZoneList: TimeZoneDto[] = useSelector<RootStateType, TimeZoneDto[]>(
    (state) => state.timeZone
  );
  const dispatch = useDispatch();
  const { setOpenSuccessDialog } = useInfoDialogs();
  const { stewartUserGridRef } = useComponentRefs();
  const stewartDialogWithConfirmRef = useRef<StewartDialogWithConfirmRefType>(null);

  useImperativeHandle(ref, () => ({
    open: async () => {
      await dispatch(getProfileAsyncAction());
      stewartDialogWithConfirmRef?.current?.open();
    },
  }));

  /**
   * Create a New User Method
   * @param {ProfileDto} data
   * @param setErrors
   * @return {Promise<void>}
   */
  async function onSubmit(data: ProfileDto, { setErrors }: FormikHelpers<CreateUserBody>) {
    try {
      await dispatch(updateProfileAsyncAction(data));
      stewartDialogWithConfirmRef?.current?.closeWithoutValidation();
      setOpenSuccessDialog(true);
      stewartUserGridRef.current?.refresh();
    } catch (error: any) {
      setFormErrors(setErrors, error);
    }
  }

  function contentBlock({ handleChange }: Partial<FormikHandlers>): ReactJSXElement {
    return (
      <Box style={{ maxWidth: '360px' }}>
        <StewartInput
          onChange={handleChange}
          name="displayName"
          placeholder={label('lbl_display_name')}
        />
        <StewartInput
          onChange={handleChange}
          name="firstName"
          placeholder={label('lbl_first_name')}
        />
        <StewartInput
          onChange={handleChange}
          name="lastName"
          placeholder={label('lbl_last_name')}
        />
        <StewartSelect
          optionsTextField="displayName"
          optionsValueField="name"
          options={timeZoneList}
          onChange={handleChange}
          name="timeZone"
          placeholder={label('lbl_user_time_zone')}
        />
      </Box>
    );
  }

  function actionsBlock({ handleSubmit }: Partial<FormikHandlers>): ReactJSXElement {
    return (
      <Box>
        <StewartCancelButton
          sx={{ marginRight: '10px' }}
          onClick={stewartDialogWithConfirmRef?.current?.close}
        />

        <PermissionRestrictedComponent permissionCode={PERMISSIONS.USERS_MODIFY_PROFILE}>
          <StewartSaveOrConfirmButton
            disabled={!stewartDialogWithConfirmRef?.current?.getIsTouched()}
            onClick={() => handleSubmit?.()}
          />
        </PermissionRestrictedComponent>
      </Box>
    );
  }

  return (
    <>
      <StewartDialogWithConfirmationChanges
        ref={stewartDialogWithConfirmRef}
        title={message('msg_profile_settings')}
        initialValue={{
          ...INITIAL_VALUES,
          ...profileInfo,
        }}
        validationSchema={validationSchema}
        contentBlock={contentBlock}
        actionsBlock={actionsBlock}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default forwardRef<EditProfileDialogRefType, EditProfileDialogPropsType>(EditProfileDialog);
