import React, { useEffect, useRef } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Button } from '@mui/material';

import StewartDialog from '@stewart/common-ui/dialogs/StewartDialog';
import { StewartDialogRefType } from '@stewart/common-ui/dialogs/StewartDialog/models';
import TickIcon from '@stewart/common-ui/icons/TickIcon';
import { useInfoDialogs } from '@stewart/core/contexts/InfoDialogs';
import { label } from '@stewart/core/services';

// Component
const StewartSuccessDialog = () => {
  const { openSuccessDialog, setOpenSuccessDialog, successMessage, setSuccessMessage } =
    useInfoDialogs();
  const stewartDialogRef = useRef<StewartDialogRefType>(null);

  const onCloseCallback = () => {
    setOpenSuccessDialog(false);
    setSuccessMessage('');
  };

  const closeDialog = () => {
    onCloseCallback();
    stewartDialogRef?.current?.close();
  };

  const isOpen = !stewartDialogRef?.current?.isOpened() && openSuccessDialog;

  useEffect(() => {
    if (isOpen) stewartDialogRef?.current?.open();
  }, [isOpen]);

  function contentBlock(): ReactJSXElement {
    return (
      <Box style={{ maxWidth: '28vw' }}>{successMessage || `${label('lbl_successful')}!`}</Box>
    );
  }

  function actionsBlock(): ReactJSXElement {
    return (
      <Box>
        <Button
          style={{ minWidth: '100px' }}
          variant="contained"
          onClick={closeDialog}
          startIcon={<TickIcon />}
        >
          {label('lbl_ok')}
        </Button>
      </Box>
    );
  }

  return (
    <>
      <StewartDialog
        title={label('lbl_success')}
        ref={stewartDialogRef}
        contentBlock={contentBlock}
        actionsBlock={actionsBlock}
        type="success"
        onCloseCallback={onCloseCallback}
      />
    </>
  );
};

export default StewartSuccessDialog;
