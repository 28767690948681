import { numericFormatter } from 'react-number-format';

import { HandleSubmitType } from '@stewart/common-ui/dialogs/StewartForm/models';
import { DispatchSetStateActionType } from '@stewart/core/models';
import { ApprovalStateDto, TypeDto } from '@stewart/core/rest/models/uw-request.models';
import { message } from '@stewart/core/services';
import yup from '@stewart/core/utils/validation.utils';

export type ApprovalInfoFieldsType = {
  requestNumber?: string;
  dateReceived?: string;
  dateCreated?: string;
  dateUpdated?: string;
  fileNumber?: string;
  policyNumber?: string;
  policyType: string;
  approvalState: string;
  approvalLevel: string;
  authorityLimit?: number;
  projectReferenceNumber?: string;
  subType: string;
  type: string;
  requestedAmount: string;
  approvedAmount?: string;
  titleExaminedFrom?: string;
  titleExaminedTo?: string;
  status: string;
  subStatus: string;
  group?: string;
  priority: string;
  ownerName?: string;
  projectName?: string;
  summary?: string;
  dateCreatedByName?: string;
  dateUpdatedByName?: string;
  isSUCFile?: boolean;
  isUACFile?: boolean;
  anticipatedClosingDate?: string;
};

export type ApprovalInfoFieldsCallbackType = ApprovalInfoFieldsType & {
  ownerID: string;
};

export type NewApprovalInfoProps = {
  approvalInitialValues: ApprovalInfoFieldsType;
  callbackForField: (partialData: Partial<ApprovalInfoFieldsCallbackType>) => Promise<void>;
  requestId: number;
  saveHandleSubmit: (handler: HandleSubmitType) => void;
  types: TypeDto[];
  approvalStates: ApprovalStateDto[];
  getApprovalLevelCalculation: (fieldName: string, value: string | number | boolean) => void;
  onStatusChange: (status: string) => void;
  onStateChange: (stateAbbr: string) => void;
  refresh: () => void;
  isFormDisabled: boolean;
  onApprovedAmount: () => Promise<void>;
  currentUserLevel: number;
  currentUserAuthorityLimit: number;
  isReadOnly: boolean;
  onGroupChange: (groupCode: string) => void;
  validateForm: () => boolean;
  setOwnerButtonTouched: DispatchSetStateActionType<boolean>;
  isForDisabledForUnderwritersOnly: boolean;
  uwaUserTypeCode: string;
};

export const APPROVAL_INITIAL_VALUES: ApprovalInfoFieldsType = {
  requestNumber: '',
  dateReceived: '',
  dateCreated: '',
  fileNumber: '',
  policyNumber: '',
  policyType: '',
  approvalState: '',
  approvalLevel: '',
  authorityLimit: 0,
  projectReferenceNumber: '',
  subType: '',
  type: '',
  requestedAmount: '',
  approvedAmount: '',
  titleExaminedFrom: '',
  titleExaminedTo: '',
  status: '',
  subStatus: '',
  group: '',
  priority: '',
  ownerName: '',
  projectName: '',
  summary: '',
  dateCreatedByName: '',
  dateUpdatedByName: '',
  dateUpdated: '',
  isSUCFile: false,
  isUACFile: false,
  anticipatedClosingDate: '',
};

export const approvalInfoSchema = yup.object({
  requestNumber: yup.string().trim().optional().max(100),
  dateReceived: yup.string().trim().optional().max(100),
  dateCreated: yup.string().trim().optional().max(100),
  dateUpdated: yup.string().trim().optional(),
  fileNumber: yup.string().trim().required().max(100),
  policyNumber: yup.string().trim().optional().max(100),
  policyType: yup.string().trim().required().max(100),
  approvalState: yup.string().trim().required().max(4),
  approvalLevel: yup.string().trim().required().max(30),
  authorityLimit: yup.number().optional(),
  projectReferenceNumber: yup.string().trim().optional().max(150),
  type: yup.string().trim().required().max(30),
  subType: yup.string().trim().required().max(30),
  requestedAmount: yup.string().trim().required().max(100),
  approvedAmount: yup
    .string()
    .trim()
    .optional()
    .when(['requestedAmount'], (requestedAmount) => {
      const formatedRequestedAmt = numericFormatter(requestedAmount, {
        thousandsGroupStyle: 'thousand',
        thousandSeparator: ',',
        decimalSeparator: '.',
        decimalScale: 2,
        fixedDecimalScale: true,
      });
      return yup
        .string()
        .oneOf(
          [formatedRequestedAmt, requestedAmount.toString()],
          message('msg_uw_requested_approved_amount_not_equal')
        );
    }),
  // approvedAmount: yup
  //   .string()
  //   .trim()
  //   .when('ownerName', {
  //     is: (owner: string) => !!owner,
  //     then: yup.string().required(),
  //     otherwise: yup.string().optional(),
  //   }),
  titleExaminedFrom: yup.string().trim().optional().max(100),
  titleExaminedTo: yup.string().trim().optional().max(100),
  status: yup.string().trim().required().max(30),
  subStatus: yup.string().trim().required().max(30),
  group: yup.string().trim().optional().max(30),
  priority: yup.string().trim().required().max(30),
  ownerName: yup.string().trim().optional().max(100),
  projectName: yup.string().trim().optional().max(100),
  summary: yup.string().trim().optional().max(100),
  dateCreatedByName: yup.string().optional(),
  dateUpdatedByName: yup.string().optional(),
  isSUCFile: yup.boolean().optional(),
  isUACFile: yup.boolean().optional(),
  anticipatedClosingDate: yup.string().trim().optional().max(100),
});
