import axios, { AxiosRequestConfig } from 'axios';

import {
  composeUrl,
  composeUrlFromLocal,
  composeUrlFromLocal2,
} from '@stewart/core/http/http.utils';

/**
 * @param {string} url
 * @param {Object || null} params
 * @param {AbortSignal} signal
 * @return {Promise<any>}
 */
function get<T>(url: string, params: any = null, signal?: AbortSignal): Promise<T> {
  return axios.get(composeUrl(url), { params, signal }).then((response) => response.data);
}

/**
 * @param {string} url
 * @param {Object} data
 * @param {AxiosRequestConfig<any>} config
 * @return {Promise<any>}
 */
function put<T>(url: string, data: any = null, config?: AxiosRequestConfig<any>): Promise<T> {
  return axios.put(composeUrl(url), data, config).then((response) => response.data);
}

/**
 * @param {string} url
 * @param {Object} data
 * @param {Object} config
 * @return {Promise<any>}
 */
function post<T>(url: string, data: any = null, config?: AxiosRequestConfig<any>): Promise<T> {
  return axios.post(composeUrl(url), data, config).then((response) => response.data);
}

function postWithFullResponse(
  url: string,
  data: any = null,
  config?: AxiosRequestConfig<any>
): Promise<any> {
  return axios.post(composeUrl(url), data, config).then((response) => response);
}

function postFromLocalWithFullResponse(
  url: string,
  data: any = null,
  config?: AxiosRequestConfig<any>
): Promise<any> {
  return axios.post(composeUrlFromLocal2(url), data, config).then((response) => response);
}

/**
 * @param {string} url
 * @param {AxiosRequestConfig<any>>} config
 * @return {Promise<any>}
 */
function remove<T>(url: string, config?: AxiosRequestConfig<any>): Promise<T> {
  return axios.delete(composeUrl(url), config).then((response) => response.data);
}

/**
 * @param {string} url
 * @param {Object || null} params
 * @param {AbortSignal} signal
 * @return {Promise<any>}
 */
function getFromLocal<T>(url: string, params: any = null, signal?: AbortSignal): Promise<T> {
  return axios.get(composeUrlFromLocal(url), { params, signal }).then((response) => response.data);
}

/**
 * @param {string} url
 * @param {Object} data
 * @param {Object} config
 * @return {Promise<any>}
 */
function postFromLocal<T>(
  url: string,
  data: any = null,
  config?: AxiosRequestConfig<any>
): Promise<T> {
  return axios.post(composeUrlFromLocal(url), data, config).then((response) => response.data);
}

export const httpRequests = {
  get,
  put,
  post,
  remove,
  getFromLocal,
  postFromLocal,
  postWithFullResponse,
  postFromLocalWithFullResponse,
};
