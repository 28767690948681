import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const CancelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 12 12"
      {...props}
      style={{
        width: props.style?.width || 10,
      }}
    >
      <path
        d="M11.8334 1.34163L10.6584 0.166626L6.00002 4.82496L1.34169 0.166626L0.166687 1.34163L4.82502 5.99996L0.166687 10.6583L1.34169 11.8333L6.00002 7.17496L10.6584 11.8333L11.8334 10.6583L7.17502 5.99996L11.8334 1.34163Z"
        fill="#2E4289"
      />
    </SvgIcon>
  );
};

export default CancelIcon;
