import { useLayoutEffect, useState } from 'react';

import { ObjectType } from '@stewart/core/models';

const USE_STEPPER_INIT_STATE: {
  activeStep: number;
  completedSteps: ObjectType<boolean>;
} = { activeStep: 0, completedSteps: {} };

export function useStepper(): any {
  const [state, setState] = useState<typeof USE_STEPPER_INIT_STATE>(USE_STEPPER_INIT_STATE);

  const reinit = () => {
    setState(USE_STEPPER_INIT_STATE);
  };

  const next = () => {
    const newStep: number = +state.activeStep + 1;

    setState({
      activeStep: newStep,
      completedSteps: {
        ...state.completedSteps,
        [state.activeStep]: true,
      },
    });
  };

  const back = () => {
    const newStep: number = state.activeStep - 1;

    setState({
      activeStep: newStep,
      completedSteps: {
        ...state.completedSteps,
        [newStep]: false,
      },
    });
  };

  return [state, next, back, reinit, setState];
}

export function useWindowSize(): number {
  const [size, setSize] = useState<number>(0);

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}
