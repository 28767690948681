import React from 'react';

const BuildingIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 2C11.1211 2 10 3.12109 10 4.5V7H5C3.62109 7 2.5 8.12109 2.5 9.5V19.5C2.5 20.8789 3.62109 22 5 22H12.5H20C21.3789 22 22.5 20.8789 22.5 19.5V9.5V4.5C22.5 3.12109 21.3789 2 20 2H12.5ZM5 13.875C5 13.5312 5.28125 13.25 5.625 13.25H6.875C7.21875 13.25 7.5 13.5312 7.5 13.875V15.125C7.5 15.4688 7.21875 15.75 6.875 15.75H5.625C5.28125 15.75 5 15.4688 5 15.125V13.875ZM13.125 14.5C12.7812 14.5 12.5 14.2188 12.5 13.875V12.625C12.5 12.2812 12.7812 12 13.125 12H14.375C14.7188 12 15 12.2812 15 12.625V13.875C15 14.2188 14.7188 14.5 14.375 14.5H13.125ZM17.5 13.875V12.625C17.5 12.2812 17.7812 12 18.125 12H19.375C19.7188 12 20 12.2812 20 12.625V13.875C20 14.2188 19.7188 14.5 19.375 14.5H18.125C17.7812 14.5 17.5 14.2188 17.5 13.875ZM5.625 9.5H6.875C7.21875 9.5 7.5 9.78125 7.5 10.125V11.375C7.5 11.7188 7.21875 12 6.875 12H5.625C5.28125 12 5 11.7188 5 11.375V10.125C5 9.78125 5.28125 9.5 5.625 9.5ZM12.5 5.125C12.5 4.78125 12.7812 4.5 13.125 4.5H14.375C14.7188 4.5 15 4.78125 15 5.125V6.375C15 6.71875 14.7188 7 14.375 7H13.125C12.7812 7 12.5 6.71875 12.5 6.375V5.125ZM18.125 4.5H19.375C19.7188 4.5 20 4.78125 20 5.125V6.375C20 6.71875 19.7188 7 19.375 7H18.125C17.7812 7 17.5 6.71875 17.5 6.375V5.125C17.5 4.78125 17.7812 4.5 18.125 4.5ZM12.5 10.125V8.875C12.5 8.53125 12.7812 8.25 13.125 8.25H14.375C14.7188 8.25 15 8.53125 15 8.875V10.125C15 10.4688 14.7188 10.75 14.375 10.75H13.125C12.7812 10.75 12.5 10.4688 12.5 10.125ZM18.125 10.75C17.7812 10.75 17.5 10.4688 17.5 10.125V8.875C17.5 8.53125 17.7812 8.25 18.125 8.25H19.375C19.7188 8.25 20 8.53125 20 8.875V10.125C20 10.4688 19.7188 10.75 19.375 10.75H18.125Z"
        fill="white"
      />
    </svg>
  );
};

export default BuildingIcon;
