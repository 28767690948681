import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';
import { colors } from '@stewart/theme';

const DropdownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        style={{ transform: 'translateY(-6px) !important' }}
        d="M7 10l5 5 5-5z"
        fill={props.htmlColor || colors.blue14}
      />
    </SvgIcon>
  );
};

export default DropdownIcon;
