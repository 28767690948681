import React from 'react';

import { Button, ButtonProps } from '@mui/material';

import TickIcon from '@stewart/common-ui/icons/TickIcon';
import { label } from '@stewart/core/services';

type StewartSaveOrConfirmButtonProps = ButtonProps & {
  positiveActionTitle?: string | undefined;
};
function StewartSaveOrConfirmButton({
  sx,
  onClick,
  positiveActionTitle,
  disabled,
}: StewartSaveOrConfirmButtonProps) {
  return (
    <Button
      sx={{ ...sx, height: '36px' }}
      variant="contained"
      startIcon={<TickIcon style={{ maxWidth: '20px' }} />}
      onClick={onClick}
      disabled={disabled}
    >
      {positiveActionTitle || label('lbl_save')}
    </Button>
  );
}

StewartSaveOrConfirmButton.defaultProps = {
  positiveActionTitle: '',
};

export default StewartSaveOrConfirmButton;
