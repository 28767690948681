import React, { useEffect, useRef, useState } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Tooltip } from '@mui/material';
import { fontSize } from '@stewart/theme/index';
import { StewartTextPropsType } from '@stewart/common-ui/components/StewartText/models';
import { useStewartTextStyles } from '@stewart/common-ui/components/StewartText/style';
import { HtmlElementGeneric } from '@stewart/core/models';

export function StewartText({
  children,
  isShowTooltip,
  maxRows,
  lineHeight,
}: StewartTextPropsType): ReactJSXElement {
  const classes = useStewartTextStyles({
    maxRows,
    lineHeight,
  });
  const [isDisplayTooltip, setDisplayTooltip] = useState<boolean>(false);
  const ref = useRef<HtmlElementGeneric<HTMLDivElement, HTMLSpanElement>>(null);
  const clearText: string = children ? children.replace(/\s+/g, ' ') : '';

  useEffect(() => {
    if (ref?.current) {
      setDisplayTooltip(false);

      const container: HtmlElementGeneric<HTMLDivElement, HTMLSpanElement> = ref.current;
      const containerHeight: number = container.clientHeight;
      const span: HTMLSpanElement = container.children[0];
      let countOfSlice: number = 1;

      while (span.offsetHeight > containerHeight) {
        span.innerText = `${span.innerText.slice(0, -countOfSlice)}...`;
        countOfSlice = 4;
        setDisplayTooltip(isShowTooltip! && true);
      }
    }
  }, [ref, clearText]);

  return (
    <>
      <Tooltip title={isDisplayTooltip ? clearText : ''}>
        <div ref={ref} className={classes.textContainer}>
          <span style={{ fontSize: fontSize.medium }}>{clearText}</span>
        </div>
      </Tooltip>
    </>
  );
}

StewartText.defaultProps = {
  isShowTooltip: true,
  maxRows: 1,
  lineHeight: 'normal',
};
