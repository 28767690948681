import React from 'react';

import { Button, ButtonProps } from '@mui/material';

import CancelIcon from '@stewart/common-ui/icons/CancelIcon';
import { label } from '@stewart/core/services';

type StewartCancelButtonProps = ButtonProps & {
  negativeActionTitle?: string | undefined;
};
function StewartCancelButton({ sx, onClick, negativeActionTitle }: StewartCancelButtonProps) {
  return (
    <Button
      sx={{ ...sx, height: '36px' }}
      variant="outlined"
      startIcon={<CancelIcon style={{ maxWidth: '20px' }} />}
      onClick={onClick}
    >
      {negativeActionTitle || label('lbl_cancel')}
    </Button>
  );
}

StewartCancelButton.defaultProps = {
  negativeActionTitle: '',
};

export default StewartCancelButton;
