import React, { createContext, ReactElement, useContext, useState } from 'react';

export type InfoDialogsContextType = {
  openSuccessDialog: boolean;
  openErrorDialog: boolean;
  errorMessages: string[];
  successMessage: string;
  setOpenSuccessDialog: Function;
  setOpenErrorDialog: Function;
  setErrorMessage: (message: string[]) => void;
  setSuccessMessage: Function;
};

// Context
const InfoDialogsContext = createContext<InfoDialogsContextType>({} as InfoDialogsContextType);

// Provider
export function InfoDialogsProvider({ children }: { children: ReactElement }) {
  const [openSuccessDialog, setOpenSuccessDialog] = useState<boolean>(false);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorMessages, setErrorMessage] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>('');

  return (
    <InfoDialogsContext.Provider
      value={{
        openSuccessDialog,
        setOpenSuccessDialog,
        setSuccessMessage,
        successMessage,
        openErrorDialog,
        setOpenErrorDialog,
        errorMessages,
        setErrorMessage,
      }}
    >
      {children}
    </InfoDialogsContext.Provider>
  );
}

export const useInfoDialogs = (): InfoDialogsContextType =>
  useContext<InfoDialogsContextType>(InfoDialogsContext);
