import { Dispatch } from 'redux';

import { GET_PROFILE_ACTION } from '@stewart/core/redux/types/profile.type';
import { ProfileDto } from '@stewart/core/rest/models/user.models';
import { getProfile, updateProfile } from '@stewart/core/rest/requests/users.rest';

/**
 * Get and Save to Store Current Stewart Profile
 * @return {Function}
 */
export function getProfileAsyncAction(): Function {
  return async (dispatch: Dispatch<any>) => {
    const result: ProfileDto = await getProfile();
    dispatch({ type: GET_PROFILE_ACTION, payload: result });
  };
}

/**
 * Update and Save to Store Current Stewart Profile
 * @param {ProfileDto} data
 * @return {Function}
 */
export function updateProfileAsyncAction(data: ProfileDto): Function {
  return async (dispatch: Dispatch<any>) => {
    await updateProfile(data);
    const result: ProfileDto = await getProfile();
    dispatch({ type: GET_PROFILE_ACTION, payload: result });
  };
}
