import React, { useRef } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ClickAwayListener, Fade, Paper, Popper } from '@mui/material';
import { NAVIGATION_ITEMS } from '@stewart/common-ui/components/StewartHeader';
import { useMobileNavbarStyles } from '@stewart/common-ui/components/StewartHeader/components/MobileNavbar/style';
import NavigationItem from '@stewart/common-ui/components/StewartHeader/components/NavigationItem';
import { NavigationItemPropsType } from '@stewart/common-ui/components/StewartHeader/components/NavigationItem/models';
import EditProfileDialog from '@stewart/common-ui/dialogs/EditProfileDialog';
import { EditProfileDialogRefType } from '@stewart/common-ui/dialogs/EditProfileDialog/models';
import { PermissionStore } from '@stewart/core/redux/reducers/permission.reducer';
import { RootStateType } from '@stewart/core/redux/redux.models';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { StewartMobileNavbarPropsTypes } from './models';

export default function StewartMobileNavbar({
  isMobileOpen,
  setMobileOpen,
  title,
  navIcon,
}: StewartMobileNavbarPropsTypes): ReactJSXElement {
  const navItemAnchor = React.useRef<HTMLButtonElement>(null);
  const classes = useMobileNavbarStyles();
  const { permissionCodes } = useSelector<RootStateType, PermissionStore>(
    (state) => state.permission
  );
  const handleCloseNavDropdown = (event: any) => {
    if (navItemAnchor.current && navItemAnchor.current.contains(event.target as HTMLElement)) {
      return;
    }
    setMobileOpen(false);
  };
  const editProfileDialogRef = useRef<EditProfileDialogRefType>(null);
  const list = () => (
    <Box>
      <List>
        {NAVIGATION_ITEMS.filter((data: NavigationItemPropsType) =>
          permissionCodes.includes(data.permissionCode)
        ).map((data: NavigationItemPropsType): any => (
          <NavigationItem key={uuidv4()} {...data} closeMenu={setMobileOpen} />
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <IconButton
        id="hamburgerMenuItem"
        disableRipple={true}
        className={classes.activeNavItem}
        color="inherit"
        onClick={() => setMobileOpen(!isMobileOpen)}
        ref={navItemAnchor}
      >
        {title ? (
          <>
            {navIcon}
            <Box className={classes.title}>{title}</Box>
            {!isMobileOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </>
        ) : (
          <>
            <MenuIcon />
          </>
        )}
      </IconButton>

      <Popper
        open={isMobileOpen}
        disablePortal
        className={`${classes.menuContainer} media`}
        anchorEl={navItemAnchor && navItemAnchor?.current}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={2000}>
            <>
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNavDropdown}>{list()}</ClickAwayListener>
              </Paper>
            </>
          </Fade>
        )}
      </Popper>
      <EditProfileDialog ref={editProfileDialogRef} />
    </>
  );
}
