import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { permissionReducer } from '@stewart/core/redux/reducers/permission.reducer';
import { preloaderReducer } from '@stewart/core/redux/reducers/preloader.reducer';
import { profileReducer } from '@stewart/core/redux/reducers/profile.reducer';
import { timeZoneReducer } from '@stewart/core/redux/reducers/time-zone.reducer';
import { uwReducer } from '@stewart/core/redux/reducers/uw.reducer';

/**
 * Initialization Reducers
 * @param {any} history
 */
export default function initRootReducer(history: any) {
  return combineReducers({
    router: connectRouter(history),
    timeZone: timeZoneReducer,
    profile: profileReducer,
    preloader: preloaderReducer,
    permission: permissionReducer,
    uw: uwReducer,
  });
}
