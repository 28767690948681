import { Dispatch } from 'redux';

import { GET_TIME_ZONE_LIST_ACTION } from '@stewart/core/redux/types/time-zone.type';
import { TimeZoneDto } from '@stewart/core/rest/models/lookup.models';
import { getTimeZoneList } from '@stewart/core/rest/requests/lookup.rest';

/**
 * Get and Save to Store Stewart Timezone List
 * @return {Function}
 */
export function getTimeZoneListAsyncAction(): Function {
  return async (dispatch: Dispatch<any>) => {
    const result: TimeZoneDto[] = await getTimeZoneList();
    dispatch({ type: GET_TIME_ZONE_LIST_ACTION, payload: result });
  };
}
