export enum PolicyImageTabName {
  New = 'New',
  Archive = 'Archive',
}

export enum PageType {
  NewImagePage = 'NEWPAGE',
  MyFilesPage = 'MYFILESPAGE',
  ReportPayPage = 'REPORTPAYPAGE',
  PendingPayPage = 'PENDINGPAYPAGE',
  ArchiveImagePage = 'ARCHIVEIMAGEPAGE',
}

export enum LocalStorageKeys {
  COLUMNS_IMAGE_NEW = 'columns-image-new',
  COLUMNS_IMAGE_NEW_HIDDEN = 'columns-image-new-hidden',
  COLUMNS_IMAGE_ARCHIVE = 'columns-image-archive',
  COLUMNS_IMAGE_ARCHIVE_HIDDEN = 'columns-image-archive-hidden',
  COLUMNS_USERS = 'columns-users',
  COLUMNS_USERS_HIDDEN = 'columns-users-hidden',
}
export enum DateTypeCode {
  Created = 'CREATED_DATE',
  Modified = 'MODIFIED_DATE',
  EffectiveDate = 'EFFECTIVE_DATE',
  IssueDate = 'ISSUE_DATE',
  ReportedDate = 'BILLED_DATE',
  UploadDate = 'UPLOADED_DATE',
  ProcessedDate = 'PROCESSED_DATE',
}

export enum PERMISSIONS {
  CREATE_FILE_ACCESS = 'CREATE_FILE_ACCESS',
  FILES_SEARCH = 'FILES_SEARCH',
  HELP_ACCESS = 'HELP_ACCESS',
  VIEW_BILLING = 'VIEW_BILLING',
  POLICYIMAGES_ACCESS = 'POLICYIMAGES_ACCESS',
  RESOURCES_ACCESS = 'RESOURCES_ACCESS',
  VIEW_REPORTS = 'VIEW_REPORTS',
  POLICYIMAGES_SEARCH = 'POLICYIMAGES_SEARCH',
  POLICYIMAGES_DELETE = 'POLICYIMAGES_DELETE',
  POLICYIMAGES_MODIFY = 'POLICYIMAGES_MODIFY',
  SIGNATURES_MANAGE = 'SIGNATURES_MANAGE',
}

export enum SessionStorageKeys {
  APP_VERSION = 'app-version',
  APP_VERSION_PENDING = 'app-version-pending',
  APP_RELOAD_REQUIRED = 'app-reload-required',
  FEATURE_CONFIG = 'feature-config',
  GENERAL_CONFIG = 'general-config',
  GLOBAL_ACCESS_SELECTED_AGENCY = 'selectedAgency',
  USE_PRIOR_SEARCH_CRITERIA = 'usePriorSearchCriteria',
  USE_PRIOR_SEARCH_CRITERIA_ADVANCE_SEARCH = 'usePriorSearchCriteriaAdvanceSearch',
  REPORT_PAY_STORED_CRITERIA = 'reportPayStoredCriteria',
  LIST_FILE_STORED_CRITERIA = 'listFileStoredCriteria',
  PENDING_PAY_STORED_CRITERIA = 'pendingPayStoredCriteria',
  SELECTED_FILES_REPORT_PAY = 'selectedFilesReportPay',
  SELECTED_FILES_PENDING_PAY = 'selectedFilesPendingPay',
  LANDING_PAGE = 'landing_page',
}

export enum UIConstants {
  LOADING_IN_PROGRESS_MESSAGE = 'Please wait while your data is being prepared',
  SAVE_DOCUMENT_IN_PROGRESS_MESSAGE = 'Please wait while saving your policy document',
  POLICY_UPLOAD_DOWNLOAD_MESSAGE = 'There was a problem downloading your documents. Please try again or contact the Agency Support Center for assistance.',
  POLICY_UPLOAD_REUPLOAD_MESSAGE = 'There was a problem reuploading your documents. Please try again or contact the Agency Support Center for assistance',
}

export enum NavigateToType {
  Url = 'URL',
  Tab = 'TAB',
  Button = 'BUTTON',
  Adb2c = 'ADB2C',
}

export enum ActionResultStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED',
}
export enum DownloadFileType {
  Mp4 = 'mp4',
  Pdf = 'pdf',
  Xlsx = 'xlsx',
}
export enum DialogModuleName {
  ASSIGN_PERMISSION = 'ASSIGN_PERMISSION',
  EDIT_UNDERWRITER_CONFIGURATION = 'EDIT_UNDERWRITER_CONFIGURATION',
  NEW_PERMISSION_SET = 'NEW_PERMISSION_SET',
}
