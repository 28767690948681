import React, { useEffect } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import axios from 'axios';

import { InterceptorsType } from '@stewart/core/models';
import { tokenStorage } from '@stewart/core/services';

export default function JwtInterceptor({ subscriptionKey }: InterceptorsType): ReactJSXElement {
  useEffect(() => {
    axios.interceptors.request.use(
      async (request) => {
        const token = tokenStorage.read();

        request.headers.set('Authorization', `Bearer ${token}`);
        request.headers.set('Ocp-Apim-Subscription-Key', subscriptionKey);
        return request;
      },
      (error) => Promise.reject(error)
    );
  }, []);

  return <></>;
}
