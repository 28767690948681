import React, { forwardRef, Ref, useImperativeHandle, useRef, useState } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';

import StewartSaveOrConfirmButton from '@stewart/common-ui/components/ActionButtons/StewartSaveOrConfirmButton';
import { PERMISSIONS } from '@stewart/common-ui/components/AuthenticatedApp/utils';
import { NAVIGATION_ITEMS } from '@stewart/common-ui/components/StewartHeader';
import {
  PermissionUpdatedDialogPropsType,
  PermissionUpdatedDialogRefType,
} from '@stewart/common-ui/dialogs/PermissionUpdatedDialog/models';
import StewartDialog from '@stewart/common-ui/dialogs/StewartDialog';
import { StewartDialogRefType } from '@stewart/common-ui/dialogs/StewartDialog/models';
import { HELP_CENTER_PATH, ROOT_PATH } from '@stewart/core/routing/routes/root.routes';
import { label } from '@stewart/core/services';

// Component
const PermissionUpdatedDialog = (
  { onConfirm, message, forceConfirmDialog }: PermissionUpdatedDialogPropsType,
  ref: Ref<PermissionUpdatedDialogRefType>
) => {
  const stewartDialogRef = useRef<StewartDialogRefType>(null);
  const [deletedPermissions, setDeletedPermissions] = useState<string[]>([]);

  const [addedPermissions, setAddedPermissions] = useState<string[]>([]);

  const history = useHistory();

  function goHome() {
    window.location.assign(ROOT_PATH);
  }

  useImperativeHandle(ref, () => ({
    open: (deletedSets, addedSets) => {
      setDeletedPermissions(deletedSets);
      setAddedPermissions(addedSets);
      stewartDialogRef?.current?.open();
    },

    close: () => {
      if (!deletedPermissions.length && !addedPermissions.length) return;
      if (deletedPermissions.length) {
        const actionType = deletedPermissions?.map?.((permission: any) => {
          const { pathname } = history.location;
          const { permissionItemTypeCode } = permission;

          return [
            ...NAVIGATION_ITEMS,
            { permissionCode: PERMISSIONS.HELP_CENTER_ACCESS, path: HELP_CENTER_PATH },
          ].find(
            (item: any) => item.permissionCode === permissionItemTypeCode && item.path === pathname
          )
            ? { type: 'redirect' }
            : { type: 'refresh' };
        });
        if (actionType.find((el) => el.type === 'redirect')) {
          goHome();
        } else history.go(0);
      }
      if (addedPermissions.length) {
        history.go(0);
      }
      stewartDialogRef?.current?.close();
    },
  }));

  function contentBlock(): ReactJSXElement {
    return <Box style={{ maxWidth: '28vw', overflowWrap: 'break-word' }}>{message}</Box>;
  }

  function actionsBlock(): ReactJSXElement {
    return (
      <Box>
        <StewartSaveOrConfirmButton
          positiveActionTitle={label('lbl_refresh')}
          onClick={() => onConfirm()}
        />
      </Box>
    );
  }

  return (
    <>
      <StewartDialog
        ref={stewartDialogRef}
        title={label('lbl_confirmation')}
        contentBlock={contentBlock}
        actionsBlock={actionsBlock}
        type="info"
        forceConfirmDialog={forceConfirmDialog}
        disableEscapeKeyDown
      />
    </>
  );
};

export default forwardRef<PermissionUpdatedDialogRefType, PermissionUpdatedDialogPropsType>(
  PermissionUpdatedDialog
);
