import React from 'react';

const FilePenIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 4.37454C1.5 3.06483 2.54654 2 3.83374 2H9.66808V6.74907C9.66808 7.40578 10.1895 7.93634 10.835 7.93634H15.5024V12.6001L12.3373 15.8205C11.9617 16.2027 11.6992 16.6776 11.5715 17.2007L10.8897 19.9797C10.8058 20.321 10.824 20.6772 10.9371 21H3.83374C2.54654 21 1.5 19.9352 1.5 18.6255V4.37454ZM15.5024 6.74907H10.835V2L15.5024 6.74907ZM21.5483 10.745L22.0734 11.2792C22.6422 11.858 22.6422 12.7967 22.0734 13.3792L21.0013 14.47L18.4123 11.8358L19.4844 10.745C20.0532 10.1662 20.9758 10.1662 21.5483 10.745ZM12.8733 17.4716L17.5846 12.678L20.1736 15.3122L15.4623 20.1021C15.3128 20.2542 15.1268 20.3618 14.919 20.4138L12.7275 20.9703C12.5269 21.0223 12.3191 20.9629 12.1732 20.8145C12.0273 20.6661 11.969 20.4546 12.0201 20.2505L12.567 18.0207C12.6181 17.8129 12.7238 17.62 12.8733 17.4679V17.4716Z"
        fill="white"
      />
    </svg>
  );
};

export default FilePenIcon;
