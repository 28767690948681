import { HandleSubmitType } from '@stewart/common-ui/dialogs/StewartForm/models';

export type AgencyInfoFieldsType = {
  agencyID?: string;
  agencyType?: string;
  agency?: string;
  agencyAddress?: string;
  phone?: string;
  fax?: string;
};

export const AGENCY_INFO_INITIAL_VALUES: AgencyInfoFieldsType = {
  agencyID: '',
  agencyType: '',
  agency: '',
  agencyAddress: '',
  phone: '',
  fax: '',
};

export type AgencyItemType = {
  agencyID: string;
  agencyName: string;
  address: string;
  address2: string;
};

export type NewAgencyInfoPropsType<T> = {
  callbackForField: (partialData: Partial<T>) => void;
  saveHandleSubmit: (handler: HandleSubmitType) => void;
  agencyInfoInitialValues: AgencyInfoFieldsType;
  isReadOnly: boolean;
  isFormDisabled: boolean;
};
