import {
  INCREASE_PRELOADER_ACTION,
  DECREASE_PRELOADER_ACTION,
} from '@stewart/core/redux/types/preloader.type';

/**
 * Increase count
 */
export function increasePreloaderAction() {
  return { type: INCREASE_PRELOADER_ACTION };
}

/**
 * Decrease count
 */
export function decreasePreloaderAction() {
  return { type: DECREASE_PRELOADER_ACTION };
}
