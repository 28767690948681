import React from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Button, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router';

import { useNavigationItemStyles } from '@stewart/common-ui/components/StewartHeader/components/NavigationItem/style';

import { NavigationItemPropsType } from './models';

const NavigationItem = ({
  title,
  icon: Icon,
  path,
  closeMenu,
}: NavigationItemPropsType): ReactJSXElement => {
  const classes = useNavigationItemStyles();

  const history = useHistory();
  const { pathname } = useLocation();

  function clickHandler(): void {
    history.push(path);
    if (closeMenu) {
      closeMenu(false);
    }
  }
  return (
    <Button
      color="inherit"
      className={`${[classes.root, pathname === path && classes.active].join(' ')} media`}
      onClick={clickHandler}
      id={title.replace(/\s/g, '')}
    >
      <Icon className={classes.icon} />
      <Typography className={`${classes.title} media`} variant="subtitle1">
        {title}
      </Typography>
    </Button>
  );
};

export default NavigationItem;
